import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'domSeguro'
})
export class DomSeguroPipe implements PipeTransform {

  constructor(private domSatinizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl {
    return this.domSatinizer.bypassSecurityTrustResourceUrl(`${url}`)
  };

}
