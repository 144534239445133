import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faPercent,
  faColumns,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faTimesCircle,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { Observable, Subject } from "rxjs";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { utils, writeFile } from "xlsx";

declare var $: any;
export interface columnHeader {
  headerName: string;
  field?: string;
  pipe?: string;
  sortable?: boolean;
  filterable?: boolean;
  class?: string;
  moneda?: string;
  text?: string;
  textField?: string;
  filterSelectItems?: any[];
  filterItemsProps?: any;
  visible?: boolean;
  filterDate?: boolean;
  filterProp?: string;
  filterValue?: any;
  filterInput?: boolean;
  filterRange?: boolean;
  filterRange2?: boolean;
  filterValueFrom?: any;
  filterValueTo?: any;
  filterValueFrom2?: any;
  filterValueTo2?: any;
  defaultInfo?: string;
  function?: any;
  postfix?: string;
  filterType?: string;
  filterSearchItems?: any[];
  filterSearchText?: any[];
  actionableType?: string;
  idResponsable?: string;
  responsableField?: string;
  stateField?: string;
  checkDisabled?: boolean;
  is_tooltip?: boolean;
  tooltip_text?: string;
  downloadFile?: boolean;
  defaultValue?: any;
  columnaWidth?: any;
  multipleSelectedItems?: boolean;
  sumCol?: boolean;
  tipoCambio?: string;
}

// El datatable recibe los valores de renderizado, pueden ser columnas personalizadas o elementos particulares
// Para elementos particulares adicionales se desarrollan en la seccion de PIPES en el bodu de la tabla
// La tabla retorna los EVENTOS de los botones clickeados

@Component({
  selector: "app-factoring-datatable",
  templateUrl: "./factoring-datatable.component.html",
  styleUrls: ["./factoring-datatable.component.css"],
  animations: [
    trigger("animationOption2", [
      state(
        "close",
        style({
          opacity: 0,
          height: "0px",
        })
      ),
      state(
        "open",
        style({
          height: "60px",
          opacity: 1,
        })
      ),
      transition("close <=> open", animate(250)),
    ]),
  ],
})
export class FactoringDatatableComponent implements OnInit {
  faArrowAltCircleDown = faArrowAltCircleDown;
  faArrowAltCircleUp = faArrowAltCircleUp;
  faFilter = faFilter;
  faColumns = faColumns;
  faTimes = faTimesCircle;
  faPercent = faPercent;
  faLongArrowAltRight = faLongArrowAltRight;
  faLongArrowAltLeft = faLongArrowAltLeft;
  columnsToggler: columnHeader[] = [];
  _headers: columnHeader[] = [];
  filtersData: any[];
  @ContentChild("actionsButtons") actionsButtonsTMPL: TemplateRef<any>;

  private _rows: any[] = [];
  _preselected: any;
  get rows(): any[] {
    return this._rows;
  }
  _selectable: boolean = false;
  @Input() set preselected(value: any[]) {
    if (value.length > 0) {
      this._rows = this._rows.map((item) => {
        item.selected = value.some((val) => val == item.id);
        return item;
      });
      this._preselected = value;
    }

    // if(value.length == 0){
    //   this._rows = this._rows.map(( item ) => {
    //     item.selected = false;
    //     return item;
    //   })
    // }
    // else
    if (value.length == 0) {
      this._rows = this._rows.map((item) => {
        item.selected = false;
        return item;
      });
      this._preselected = value;
    }
  }

  @Input() set selectable(value) {
    if (!value) {
      this._rows = this._rows.map((item) => {
        item.selected = false;
        return item;
      });
    }
    this._selectable = value;
  }

  get selectable(): boolean {
    return this._selectable;
  }

  @Input() set rows(value: any[]) {
    this.evalScroll();
    if (this.checkAction) {
      value.forEach((row) => {
        this.checkedRow[row.id] = row.selected;
      });
    }

    if (this.selectable) {
      this._rows = value.map((item) => {
        item.selected = false;
        return item;
      });
      this.evalPreselected();
    } else this._rows = value;
  }
  @Input() set headers(headers) {
    this.setHeadersFunction(headers);
    this._headers = headers;
    if (headers) {
      headers.map((head) => {
        if (head.defaultValue) {
          this.filterClicked(head, head.defaultValue);
        }
      });
    }
  }

  get headers() {
    return this._headers;
  }

  /**
   * Variables de paginacion
   */
  @Input() vistaPreviaDisponible: boolean = false;
  @Input() showEmptyActions: boolean = false;
  @Input() actionsButtons: boolean = false;
  @Input() perPage: number = 10;
  perPageValues: number[] = [10, 15, 20, 30, 50, 100];
  perPageValueIndex: number = 0;
  @Input() actualPage: number = 1;
  @Input() countPages: any[] = [];
  @Input() totalRows: number = 0;
  @Input() pagination: boolean = false;
  @Input() pagination2: boolean = false;
  @Input() maxSize: number = 12;
  @Input() clickeable: boolean = false;

  // Estas variables determinan si se muestran estos botones del crud en la tabla, si todas estan en false, la columna de ACCIONES no se renderiza
  @Input() loading: boolean = false;
  @Input() checkAction: boolean = false;
  checkedRow = {};
  @Input() toggleColumn: boolean = false;
  @Input() disabledCheckbox: Function = () => false;
  @Input() highlighted: Function = () => "";
  @Input() functionColor: Function = () => "";
  @Input() isColor: Function = () => "";

  @Input() editAction: boolean = false;
  @Input() deleteAction: boolean = false;
  @Input() readAction: boolean = false;
  @Input() reassignAction: boolean = false;
  @Input() desembolsoAction: boolean = false;
  // New options for Planillas
  @Input() seeAttachmentAction: boolean = false;
  @Input() cerrarPlanillaAction: boolean = false;
  // New Options for Detalle de Planillas
  @Input() inhabilitarAbonoAction: boolean = false;
  @Input() identificarAbonoAction: boolean = false;
  @Input() aumentarPrioridadAction: boolean = false;
  // New Options for Llamadas
  @Input() asignarLlamadaAction: boolean = false;
  @Input() finalizarLlamadaAction: boolean = false;
  @Input() reasignarEjecutivoAction: boolean = false;

  // New Options for Clientes
  @Input() asignarEjecutivoAction: boolean = false;
  @Input() listarContactosAction: boolean = false;
  // New Options for Conceptos
  @Input() disminuirPrioridadAction: boolean = false;
  @Input() aplicarAbonoAction: boolean = false;
  // New Buttons for Contratos CAF
  @Input() resolveCAFAction: boolean = false;
  @Input() comprobantePagoAction: boolean = false;
  @Input() anexosAction: boolean = false;

  @Input() reassignActionFunction: Function = (row) => true;
  @Input() editActionFunction: Function = (row) => true;
  @Input() readActionFunction: Function = (row) => true;
  @Input() deleteActionFunction: Function = (row) => true;
  @Input() beautifulbeautiful: Function = (row) => true;
  @Input() desembolsoActionFunction: Function = (row) => true;
  // New Options for Planillas
  @Input() seeAttachmentActionFunction: Function = (row) => true;
  @Input() cerrarPlanillaActionFunction: Function = (row) => true;
  // New Options for Detalle de Planilla
  @Input() inhabilitarAbonoActionFunction: Function = (row) => true;
  @Input() identificarAbonoActionFunction: Function = (row) => true;
  @Input() aumentarPrioridadActionFunction: Function = (row) => true;

  @Input() reasignarEjecutivoActionFunction: Function = (row) => true;
  //New Options for Llamadas
  @Input() asignarLlamadaActionFunction: Function = (row) => true;
  @Input() finalizarLlamadaActionFunction: Function = (row) => true;
  //New Options for Clientes
  @Input() asignarEjecutivoActionFunction: Function = (row) => true;
  @Input() listarContactosActionFunction: Function = (row) => true;

  @Input() disminuirPrioridadActionFunction: Function = (row) => true;
  @Input() aplicarAbonoActionFunction: Function = (row) => true;
  //New Options for Contratos CAF
  @Input() resolveCAFActionFunction: Function = (row) => true;
  @Input() comprobantePagoActionFunction: Function = (row) => true;
  @Input() anexosActionFunction: Function = (row) => true;

  reassignRow = {};
  editRow = {};
  readRow = {};
  deleteRow = {};
  desembolsoRow = {};
  selectionArr = [];
  // New options for Planillas
  seeAttachmentRow = {};
  cerrarPlanillaRow = {};
  // New options for Detalle de Planilla
  inhabilitarAbonoRow = {};
  identificarAbonoRow = {};
  aumentarPrioridadRow = {};

  reasignarEjecutivoRow = {};
  asignarLlamadaRow = {};
  finalizarLlamadaRow = {};

  asignarEjecutivoRow = {};
  listarContactosRow = {};

  disminuirPrioridadRow = {};
  aplicarAbonoRow = {};
  // New Options for Contratos CAF
  resolveCAFRow = {};
  comprobantePagoRow = {};
  anexosRow = {};

  @Input() readActionFunctionPriorize: boolean = false;
  @Input() editActionFunctionPriorize: boolean = false;
  @Input() reassignActionFunctionPriorize: boolean = false;
  @Input() deleteActionFunctionPriorize: boolean = false;
  @Input() desembolsoActionFunctionPriorize: boolean = false;
  // New Options for Planillas
  @Input() seeAttachmentActionFunctionPriorize: boolean = false;
  @Input() cerrarPlanillaActionFunctionPriorize: boolean = false;
  // New options for Detalle de Planilla
  @Input() inhabilitarAbonoActionFunctionPriorize: boolean = false;
  @Input() identificarAbonoActionFunctionPriorize: boolean = false;
  @Input() aumentarPrioridadActionFunctionPriorize: boolean = false;

  @Input() reasignarEjecutivoActionFunctionPriorize: boolean = false;
  @Input() finalizarLlamadaActionFunctionPriorize: boolean = false;
  @Input() disminuirPrioridadActionFunctionPriorize: boolean = false;
  @Input() asignarLlamadaActionFunctionPriorize: boolean = false;

  @Input() asignarEjecutivoActionFunctionPriorize: boolean = false;
  @Input() listarContactosActionFunctionPriorize: boolean = false;

  @Input() aplicarAbonoActionFunctionPriorize: boolean = false;
  // New Options for Contratos CAF
  @Input() resolveCAFActionFunctionPriorize: boolean = false;
  @Input() comprobantePagoActionFunctionPriorize: boolean = false;
  @Input() anexosActionFunctionPriorize: boolean = false;

  @Input() minimal: boolean = false;
  @Input() checkTooltip: string = "Seleccionar";

  // Estos eventos se emiten cuando uno de los botones del crud es clickeado
  @Output() editEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() readEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() reassignEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() desembolsoEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() checkEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() checkChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() workflow: EventEmitter<any> = new EventEmitter<any>();
  @Output() download: EventEmitter<any> = new EventEmitter<any>();
  @Output() rejectEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClickEvent: EventEmitter<any> = new EventEmitter<any>();
  // New options for Planillas
  @Output() seeAttachmentEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() cerrarPlanillaEvent: EventEmitter<any> = new EventEmitter<any>();
  // New options for Detalle de Planilla
  @Output() inhabilitarAbonoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() identificarAbonoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() aplicarAbonoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() aumentarPrioridadEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() reasignarEjecutivoEvent: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() finalizarLlamadaEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() asignarLlamadaEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() asignarEjecutivoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() listarContactosEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() disminuirPrioridadEvent: EventEmitter<any> =
    new EventEmitter<any>();
  // New options for Contratos CAF
  @Output() resolveCAFEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() comprobantePagoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() anexosEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() verAplicacionDetalleEvent: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() descargarEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() vistaPreviaRow: EventEmitter<any> = new EventEmitter<any>();

  // Define si la primera columna muestra el indice de la fila
  @Input() indexedCol: boolean = false;
  _checkloader: any = {};
  @Input() set checkloader(value) {
    this.updateAsyncLoader(value);
  }
  rowClicked(row) {
    this.vistaPreviaRow.emit(row);
  }

  headerclasses(head) {
    let classes = "";

    if (this.minimal) {
      classes += "minimal ";
    }

    if (head.class) {
      classes += `${head.class} `;
    }

    if (this.customCellClasses) {
      classes += `${this.customCellClasses} `;
    }

    return classes;
  }

  headerStyles(head) {
    return { ...this.customCellStyles };
  }

  get checkloader() {
    return this._checkloader;
  }
  @Input() checksEditables: boolean = true;
  @Input() checksBloqued: boolean = false;
  @Input() readonly: boolean = false;
  staticFilters: string = '{"beneficiario":""}';
  checkLoading: boolean = false;
  tmp: any = null;
  tmpObj: any = {};
  public rowsBackup: any[] = [];
  @Input() minheight: number = 300;
  @Input() height: any = "auto";
  observer$: Observable<any[]>;
  observerOficiales$: Observable<any[]>;
  subjectString$ = new Subject<string>();
  subjectStringOficiales$ = new Subject<string>();
  ruc: any;
  loadingSearch: boolean = false;
  loadingSearchOficiales: boolean = false;
  formularioTesting: FormGroup;
  beneficiarioID: any;
  beneficiarioColumnaIndex: any;
  oficialColumnaIndex: any;
  openSelectSearch: boolean = false;
  scrollButtons: boolean = false;
  idScroll: string = "";
  @Input() convertToExcel: boolean = false;
  @Input() customRowClasses: any[] = [];
  @Input() customRowStyles: any = {};
  @Input() customCellClasses: string = "";
  @Input() customCellStyles: any = {};
  /**
   * Esta propiedad filtra las filas de la tabla dependiente del string
   * que sea enviado desde el padre
   */
  public _searchstring: string = "true";
  public searchResults = false;
  @Input() set searchstring(text) {
    this.searchStringFunction(text);
    this._searchstring = text;
  }
  get searchstring() {
    return this._searchstring;
  }

  paginateId = new Date().getTime();

  constructor(private _formBuilder: FormBuilder) {
    this.loadSeach();
    let date = new Date();
    this.idScroll = date.valueOf().toString();
  }

  evalScroll() {
    let object = document.getElementById(this.idScroll);
    if (!object || this.minimal) {
      return;
    }

    if (object.scrollWidth != object.offsetWidth) {
      this.scrollButtons = true;
    }
  }

  selectedRow(row) {
    if (this.clickeable) {
      this.rowClickEvent.emit(row);
    } else {
      row.selected = !row.selected;
      // this.selectionChange()
      this.selectionChange(row);
    }
  }

  selectionChange(row: any) {
    if (this.selectable) {
      this.selectionArr = this._rows.filter((row) => row.selected);
      // this.selectionEvent.emit(this.selectionArr)
      this.selectionEvent.emit(row);
    }
  }

  get selecciones() {
    // return this._rows.filter(row => row.selected)
    return this._preselected;
  }

  buttonEventHandler(row, field) {
    row.field = field;
    this.buttonEvent.emit(row);
  }
  refresh() {
    this.perPage = 10;
    this.actualPage = 1;

    this._headers.forEach((element, i) => {
      if (element.filterInput) {
        element.filterValue = "";
      }
      if (element.filterRange) {
        element.filterValueFrom = "";
        element.filterValueTo = "";
      }
      if (element.filterDate) {
        element.filterValue = [];
      }
      if (
        element.filterProp !== undefined &&
        element.filterSelectItems !== undefined
      ) {
        element.filterSelectItems.forEach((res) => {
          res.selected = false;
        });
      }
    });
    this.obtenerFiltrosActivos();
  }
  ngOnInit() {
    setTimeout(() => {
      this.evalScroll();
    }, 500);

    if (this.reassignActionFunction === null) {
      this.reassignActionFunction = () => true;
    }
    if (this.editActionFunction === null) {
      this.editActionFunction = () => true;
    }
    if (this.readActionFunction === null) {
      this.readActionFunction = () => true;
    }
    if (this.deleteActionFunction === null) {
      this.deleteActionFunction = () => true;
    }
    if (this.desembolsoActionFunction === null) {
      this.desembolsoActionFunction = () => true;
    }
    // New Options For Planillas
    if (this.seeAttachmentActionFunction === null) {
      this.seeAttachmentActionFunction = () => true;
    }
    if (this.cerrarPlanillaActionFunction === null) {
      this.cerrarPlanillaActionFunction = () => true;
    }
    // New Options for Detalle de Planilla
    if (this.inhabilitarAbonoActionFunction === null) {
      this.inhabilitarAbonoActionFunction = () => true;
    }
    if (this.identificarAbonoActionFunction === null) {
      this.identificarAbonoActionFunction = () => true;
    }
    if (this.aplicarAbonoActionFunction === null) {
      this.aplicarAbonoActionFunction = () => true;
    }

    if (this.aumentarPrioridadActionFunction === null) {
      this.aumentarPrioridadActionFunction = () => true;
    }

    if (this.reasignarEjecutivoActionFunction === null) {
      this.reasignarEjecutivoActionFunction = () => true;
    }

    if (this.asignarEjecutivoActionFunction === null) {
      this.asignarEjecutivoActionFunction = () => true;
    }

    if (this.listarContactosActionFunction === null) {
      this.listarContactosActionFunction = () => true;
    }

    if (this.disminuirPrioridadActionFunction === null) {
      this.disminuirPrioridadActionFunction = () => true;
    }

    if (this.asignarLlamadaActionFunction === null) {
      this.asignarLlamadaActionFunction = () => true;
    }

    if (this.finalizarLlamadaActionFunction === null) {
      this.finalizarLlamadaActionFunction = () => true;
    }

    // New Options for Contratos CAF
    if (this.resolveCAFActionFunction === null) {
      this.resolveCAFActionFunction = () => true;
    }
    if (this.comprobantePagoActionFunction === null) {
      this.comprobantePagoActionFunction = () => true;
    }
    if (this.anexosActionFunction === null) {
      this.anexosActionFunction = () => true;
    }

    this.formularioTesting = this._formBuilder.group({
      teststring: [""],
      oficialString: [""],
    });

    this.formularioTesting.controls.teststring.valueChanges.subscribe(
      (value) => {
        this.headers[this.beneficiarioColumnaIndex].filterValue = value;
        this.obtenerFiltrosActivos();
      }
    );

    this.formularioTesting.controls.oficialString.valueChanges.subscribe(
      (value) => {
        this.headers[this.oficialColumnaIndex].filterValue = value;
        this.obtenerFiltrosActivos();
      }
    );
    this.loadSeach();
  }

  closeBeneficiariosSearch() {
    this.formularioTesting.reset();
    this.openSelectSearch = false;
  }

  updateAsyncLoader(value) {
    if (value && value.value && this.rows[value.index]) {
      this.rows[value.index][value.value] = false;
    }
    this._checkloader = value;
  }

  /**
   * Se ejecuta cada vez que una variable dentro del componente cambia o muta
   * @param changes
   */
  ngOnChanges(changes) {
    if (changes.rows && this.rows && this.rows.length > 0) {
      this.rowsBackup = this.rows;
    }

    if (changes) {
      this.rows.forEach((row) => {
        this.reassignRow[row.id] = this.reassignActionFunction(row);
        this.editRow[row.id] = this.editActionFunction(row);
        this.readRow[row.id] = this.readActionFunction(row);
        this.deleteRow[row.id] = this.deleteActionFunction(row);
        this.desembolsoRow[row.id] = this.desembolsoActionFunction(row);
        // New option for Planillas
        this.seeAttachmentRow[row.id] = this.seeAttachmentActionFunction(row);
        this.cerrarPlanillaRow[row.id] = this.cerrarPlanillaActionFunction(row);
        // New option for Detalle de Planillas
        this.inhabilitarAbonoRow[row.id] =
          this.inhabilitarAbonoActionFunction(row);
        this.identificarAbonoRow[row.id] =
          this.identificarAbonoActionFunction(row);
        this.aumentarPrioridadRow[row.id] =
          this.aumentarPrioridadActionFunction(row);

        this.reasignarEjecutivoRow[row.id] =
          this.reasignarEjecutivoActionFunction(row);
        this.finalizarLlamadaRow[row.id] =
          this.finalizarLlamadaActionFunction(row);
        this.asignarLlamadaRow[row.id] = this.asignarLlamadaActionFunction(row);

        this.asignarEjecutivoRow[row.id] =
          this.asignarEjecutivoActionFunction(row);
        this.listarContactosRow[row.id] =
          this.listarContactosActionFunction(row);

        this.disminuirPrioridadRow[row.id] =
          this.disminuirPrioridadActionFunction(row);
        this.aplicarAbonoRow[row.id] = this.aplicarAbonoActionFunction(row);
        // New Options for Contratos CAF
        this.resolveCAFRow[row.id] = this.resolveCAFActionFunction(row);
        this.comprobantePagoRow[row.id] =
          this.comprobantePagoActionFunction(row);
        this.anexosRow[row.id] = this.anexosActionFunction(row);
      });
    }

    if (this.checksEditables) this.readCheckboxValues();
  }

  checkboxChange(event, row, field) {
    let index = this.rows.findIndex((item) => item.id == row.id);
    this.rows[index][`checkLoad${field}`] = true;
    this.checkChanged.emit({
      row: row,
      newvalue: event,
      field: field,
      checkEvent: {
        value: `checkLoad${field}`,
        index: index,
      },
    });
  }

  readCheckboxValues() {
    if (
      this.headers &&
      this.headers.length > 0 &&
      this.headers.some((item) => item.pipe == "checkbox")
    ) {
      let checks = this.headers.reduce((acc, item) => {
        if (item.pipe == "checkbox") {
          acc.push(item.field);
        }
        return acc;
      }, []);
      this.mapCheckboxValues(checks);
    }
  }

  mapCheckboxValues(fields) {
    let rows = this.rows.map((item) => {
      fields.forEach((element) => {
        item[`checkLoad${element}`] = false;
      });
      return item;
    });
    this.rows = rows;
  }

  goToPage(page) {
    /**
     * Calcula si existe la pagina que se esta
     * pidiendo a la API, tomando en cuenta la configuracion
     * del numero de resultados por pagina seleccionada
     */
    let paginationInfo = Math.ceil(this.totalRows / this.perPage);
    if (paginationInfo < page) {
      this.goToPage(page - 1);
      return;
    }

    /**
     * Emite evento de actualizacion de pagina
     */
    this.obtenerFiltrosActivos(page);
  }

  perPageUpdate(quantity) {
    this.perPage = quantity;
    this.goToPage(this.actualPage);
  }

  animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  perPageUpdatePlus() {
    let oldIndex = this.perPageValueIndex;

    if (this.perPageValueIndex + 1 >= this.perPageValues.length) {
      this.perPageValueIndex = 0;
    } else {
      this.perPageValueIndex++;
    }

    this.perPage = this.perPageValues[this.perPageValueIndex];
    const obj = document.getElementById("pagevalue");
    this.animateValue(obj, this.perPageValues[oldIndex], this.perPage, 200);
    setTimeout(() => {
      this.goToPage(this.actualPage);
      // this.debounce(this.goToPage(this.actualPage), 1000)
    }, 1500);
  }

  perPageUpdateLess() {
    let oldIndex = this.perPageValueIndex;

    if (this.perPageValueIndex - 1 <= 0) {
      this.perPageValueIndex = 0;
    } else {
      this.perPageValueIndex--;
    }

    this.perPage = this.perPageValues[this.perPageValueIndex];
    const obj = document.getElementById("pagevalue");
    this.animateValue(obj, this.perPageValues[oldIndex], this.perPage, 200);
    setTimeout(() => {
      this.goToPage(this.actualPage);
      // this.debounce(this.goToPage(this.actualPage), 1000)
    }, 1500);
  }

  debounce(fn, time) {
    var timerId = null;
    return function (e) {
      if (timerId) return;

      timerId = setTimeout(function () {
        fn(e);
        timerId = null;
      }, time);
    };
  }

  toggleHeader(header?: columnHeader) {
    if (!header) {
      this.headers.forEach((h) => (h.visible = true));
    } else {
      let index = this.headers.findIndex((head) => head.field === header.field);
      this.headers[index].visible = !this.headers[index].visible;
    }
  }

  /**
   * El evento se dispara cuando se clickea un filtro de una columna
   * @param head Recibe la cabecera del evento donde fue clickeado
   * @param filtered Recibe el item que fue seleccionado del dropdown de opciones
   */
  filterClicked(head, filtered?) {
    /**
     * Busca el indice de la columna en la que el filtro fue activado
     */
    let indexColumn = this.headers.findIndex((h) => h.field === head.field);
    if (!filtered) {
      if (head.filterInput) {
        head.filterValue = "";
        this.tmp = null;
        this.tmpObj = {};
      } else if (head.filterRange) {
        head.filterValueFrom = null;
        head.filterValueTo = null;
      } else if (head.filterRange2) {
        head.filterValueFrom2 = null;
        head.filterValueTo2 = null;
      } else {
        if (!this.headers[indexColumn].multipleSelectedItems) {
          this.headers[indexColumn].filterSelectItems.forEach(
            (f) => (f.selected = false)
          );
        }
      }
      this.obtenerFiltrosActivos();
      return;
    }
    /**
     * Busca el indice del filtro en la columna activada
     */
    let filterIndex = this.headers[indexColumn].filterSelectItems.findIndex(
      (f) => f.id === filtered.id
    );

    /**
     * Limpia todas las otras selecciones, porque se trata de una seleccion simple
     */
    if (!this.headers[indexColumn].multipleSelectedItems) {
      this.headers[indexColumn].filterSelectItems.forEach(
        (f) => (f.selected = false)
      );
    }

    /**
     * Cambia el estado de seleccion del filtro
     */
    this.headers[indexColumn].filterSelectItems[filterIndex].selected =
      !filtered.selected;

    this.obtenerFiltrosActivos();
  }

  obtenerFiltrosActivos(page: any = this.actualPage) {
    let filtros = this.headers.reduce((acc, header) => {
      if (
        header.filterable &&
        header.filterType &&
        (header.filterType == "buscarBeneficiario" ||
          header.filterType == "buscarOficial")
      ) {
        // this.openSelectSearch = true
        acc[header.filterProp] = header.filterValue;
      }

      if (header.filterable && header.filterSelectItems) {
        if (!header.multipleSelectedItems) {
          let selected = header.filterSelectItems.find((item) => item.selected);
          if (selected) acc[header.filterProp] = selected.id;
        } else {
          let selected = header.filterSelectItems.filter(
            (item) => item.selected
          );
          if (selected.length > 0)
            acc[header.filterProp] = selected.map((el) => el.id).join(",");
        }
      }
      if (header.filterable && header.filterDate) {
        header.filterProp = header.filterProp ? header.filterProp : "fecha";

        if (header.filterValue[`${header.filterProp}__gte`] !== undefined) {
          if (header.filterValue[`${header.filterProp}__gte`] != "")
            acc[`${header.filterProp}__gte`] =
              header.filterValue[`${header.filterProp}__gte`];
          if (header.filterValue[`${header.filterProp}__lte`] != "")
            acc[`${header.filterProp}__lte`] =
              header.filterValue[`${header.filterProp}__lte`];
        }
      }

      if (header.filterable && header.filterInput) {
        if (header.filterValue != "") {
          acc[header.filterProp] = header.filterValue;
        }
      }

      if (header.filterable && header.filterRange) {
        const filterProp = header.filterProp;

        if (header.filterValueFrom) {
          if (filterProp !== undefined) {
            acc[`${filterProp}__From`] = header.filterValueFrom;
          } else {
            acc["valueFrom"] = header.filterValueFrom;
          }
        }

        if (header.filterValueTo) {
          if (filterProp !== undefined) {
            acc[`${filterProp}__To`] = header.filterValueTo;
          } else {
            acc["valueTo"] = header.filterValueTo;
          }
        }
      }

      if (header.filterable && header.filterRange2) {
        if (header.filterValueFrom2)
          acc["valueFrom2"] = header.filterValueFrom2;

        if (header.filterValueTo2) acc["valueTo2"] = header.filterValueTo2;
      }
      return acc;
    }, {});

    /**
     * Esta sentencia valida si tiene filtros seleccionados nuevos,
     * SI tiene un filtro nuevo seleccionado, pide la pagina 1
     * si NO  tiene un filtro nuevo seleccionado, continua la paginacion natural
     */
    let filtroVacio = JSON.stringify(filtros) == JSON.stringify({});
    if (!filtroVacio) {
      if (this.staticFilters != JSON.stringify(filtros)) {
        page = 1;
      }
    }
    this.staticFilters = JSON.stringify(filtros);
    let updatePage = {
      page: page,
      per_page: this.perPage,
      filtros: filtros,
    };
    this.pageUpdate.emit(updatePage);
  }

  calendarOption(values, head) {
    if (!values || values.length == 0) return;

    let indexColumn = this.headers.findIndex((h) => h.field === head.field);
    const headerProp = this.headers[indexColumn].filterProp || "fecha";
    this.headers[indexColumn].filterValue[`${headerProp}__gte`] =
      this.formatDate(this.headers[indexColumn].filterValue[0]);
    this.headers[indexColumn].filterValue[`${headerProp}__lte`] =
      this.formatDate(this.headers[indexColumn].filterValue[1]);

    this.obtenerFiltrosActivos();
  }

  dateCloseToggle(head: columnHeader) {
    const headerProp = head.filterProp || "fecha";
    if (head.filterValue[`${headerProp}__gte`]) return true;
    return false;
  }

  formatDate(date) {
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      // date.getDate() +
      // " 00:00"
    );
  }

  searchStringFunction(text) {
    if (text.toLowerCase().trim() === "") {
      this.rows = this.rowsBackup;
      this.searchResults = false;
    } else {
      this.rows = this.rowsBackup.reduce((acc, value) => {
        for (var prop in value) {
          if (
            value[prop] &&
            value[prop].toString().toLowerCase().includes(text.toLowerCase())
          ) {
            acc.push(value);
            return acc;
          }
        }
        return acc;
      }, []);
      this.searchResults = true;
    }
  }

  initBuscarBeneficiarios(head, index) {
    this.beneficiarioColumnaIndex = index;
  }

  initBuscarOficial(head, index) {
    this.oficialColumnaIndex = index;
  }

  evalPreselected(value: any[] = this._preselected) {
    if (value.length > 0) {
      this._rows = this._rows.map((item) => {
        item.selected = value.some((val) => val == item.id);
        return item;
      });
      this._preselected = value;
    }

    // if(value.length == 0){
    //   this._rows = this._rows.map(( item ) => {
    //     item.selected = false
    //     return item
    //   })
    // }
    // else
    if (value.length == 0) {
      this._rows = this._rows.map((item) => {
        item.selected = false;
        return item;
      });
      this._preselected = value;
    }
  }

  setHeadersFunction(headers: columnHeader[]) {
    if (headers && headers.length > 0) {
      this.columnsToggler = headers.map((head: any, index: any) => {
        if (head.filterable && head.filterType) {
          switch (head.filterType) {
            case "buscarBeneficiario":
              head.beneficiarioValue = null;
              head.filterValue = "";
              this.initBuscarBeneficiarios(head, index);
              break;
            case "buscarOficial":
              head.beneficiarioValue = null;
              head.filterValue = "";
              this.initBuscarOficial(head, index);
              break;
          }
        }
        if (head.filterable && head.filterSelectItems) {
          head.filterSelectItems.forEach((filt) => (filt.selected = false));
        }

        if (head.filterable && head.filterDate) {
          head.filterProp = head.filterProp ? head.filterProp : "fecha";
          head.filterValue = {};
          head.filterValue[`${head.filterProp}__gte`] = "";
          head.filterValue[`${head.filterProp}__lte`] = "";
        }

        if (head.filterable && head.filterInput) {
          // head.filterValue = this.tmp ? this.tmp : '';
          head.filterValue = this.tmpObj[head.field]
            ? this.tmpObj[head.field]
            : "";
        }

        head.visible = true;
        return head;
      });
    }
  }

  ejecutaFiltro(header) {
    this.openSelectSearch = true;
  }

  clearDate(head) {
    let indexColumn = this.headers.findIndex((h) => h.field === head.field);
    const headerProp = this.headers[indexColumn].filterProp || "fecha";
    this.headers[indexColumn].filterValue[`${headerProp}__lte`] = "";
    this.headers[indexColumn].filterValue[`${headerProp}__gte`] = "";
    this.headers[indexColumn].filterValue = [];
    this.obtenerFiltrosActivos();
  }

  computedPercent(val) {
    if (val) return parseFloat(val).toFixed(2);
    else return 0;
  }

  workFlowEvent(row, header) {
    const roww = {
      ...row,
      actionableType: row[header.actionableType],
      actionableName: header.actionableType,
    };

    this.workflow.emit(roww);
  }

  downloadEvent(row, header) {
    if (!header.downloadFile) {
      return;
    }
    this.download.emit(row);
  }

  searchString(header: columnHeader) {
    let indexColumn = this.headers.findIndex((h) => h.field === header.field);
    this.tmp = this.headers[indexColumn].filterValue;
    this.tmpObj[this.headers[indexColumn].field] =
      this.headers[indexColumn].filterValue;
    this.obtenerFiltrosActivos();
  }

  /**
   * Este evento se suscribe a los cambios en el objeto que recibe la data desde el servidor
   */
  loadSeach() {
    /**
     * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
     * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
     * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value.
     * 		So that API will not hit if the current and previous value is the same
     * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
     * 		string typed by user
     */
    // this.observer$ = concat(
    //   of([]), // Items predeterminados
    //   this.subjectString$.pipe(
    //     filter((res) => {
    //       return true;
    //     }),
    //     distinctUntilChanged(),
    //     debounceTime(800),
    //     tap(() => (this.loadingSearch = true)),
    //     switchMap((term) => {
    //       return this.factoringService.buscarBeneficiariosObserver(term).pipe(
    //         catchError(() => of([])), // empty list on error
    //         tap(() => (this.loadingSearch = false))
    //       );
    //     })
    //   )
    // );
  }

  loadSeachBusquedaOficiales() {
    /**
     * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
     * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
     * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value.
     * 		So that API will not hit if the current and previous value is the same
     * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
     * 		string typed by user
     */
    // this.observerOficiales$ = concat(
    //   of([]), // Items predeterminados
    //   this.subjectStringOficiales$.pipe(
    //     filter((res) => {
    //       return true;
    //     }),
    //     distinctUntilChanged(),
    //     debounceTime(800),
    //     tap(() => (this.loadingSearchOficiales = true)),
    //     switchMap((term) => {
    //       return this.factoringService.buscarBeneficiariosObserver(term).pipe(
    //         catchError(() => of([])), // empty list on error
    //         tap(() => (this.loadingSearchOficiales = false))
    //       );
    //     })
    //   )
    // );
  }

  trackByFn(item: any) {
    return item.id;
  }

  rejectEmit(row) {
    this.rejectEvent.emit(row);
  }

  scrollHorizontal(direction) {
    let object = document.getElementById(this.idScroll);
    if (!object) {
      return;
    }
    let sectionsSize = 5;
    let scrollSize = object.scrollWidth;
    let actualPosition = object.scrollLeft;
    let scrollTo = 0;

    if (direction) {
      scrollTo = actualPosition + scrollSize / sectionsSize;
    } else {
      scrollTo = actualPosition - scrollSize / sectionsSize;
    }

    object.scroll({
      // top: 100,
      left: scrollTo,
      behavior: "smooth",
    });
  }

  allCheckedChange(bool) {
    if (bool == null) return;
    for (const row of this.rows) {
      this.checkedRow[row["id"]] = bool;
    }
  }

  changeCheck(rowId) {
    this.checkedRow[rowId] = !this.checkedRow[rowId];
  }

  generarExcel() {
    /* generate a worksheet */
    var ws = utils.json_to_sheet(this.rows);

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte sabana - ${new Date().toLocaleString()}.xlsx`);
  }

  rowStyles(row) {
    return {
      "background-color": this.highlighted(row),
      cursor: this.selectable ? "pointer" : "auto",
      ...this.customRowStyles,
    };
  }

  rowClasses(row) {
    return [
      row.selected && this.selectable ? "selectedHightlight" : "",
      !row.selected && this.selectable ? "unSelectedHightlight" : "",
      ...this.customRowClasses,
    ];
  }

  get sumCols() {
    return this.headers ? !!this.headers.find((el) => el.sumCol) : false;
  }

  sumCol(rows, header) {
    return rows.reduce((acc, el) => {
      let amount = Number(el[header.field]);
      let tipoCambio = 0;

      if (header.tipoCambioField && el[header.tipoCambioField]) {
        tipoCambio = Number(el[header.tipoCambioField]);
      } else {
        tipoCambio = Number(header.tipoCambio);
      }

      if (header.pipe === "currency_detail") {
        // Todos tienen la misma moneda
        return (acc += amount);
      }

      if (header.pipe === "currency") {
        let a_moneda = header.moneda_detail;
        let moneda = el[header.moneda];

        if (moneda == 1) {
          if (a_moneda == 1) return (acc += this.round2(amount));
          else return (acc += this.round2(amount / tipoCambio));
        } else {
          if (a_moneda == 1) return (acc += this.round2(amount * tipoCambio));
          else return (acc += this.round2(amount));
        }
      }
    }, 0);
  }

  round2(number) {
    return Math.round(number * 100) / 100;
  }
}
