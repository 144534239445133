import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {

  private _center: google.maps.LatLngLiteral;
  private _zoom: number;

  @Input() district: number;

  @Input() set center(value: google.maps.LatLngLiteral) {
    this._center = value;
  }
  @Input() set zoom(value: number) {
    this._zoom = value;
  }

  get center() {
    return this._center;
  }

  get zoom() {
    return this._zoom;
  }

  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPositions: google.maps.LatLngLiteral[] = [];
  display: any;

  @Output() clickMapEvent: EventEmitter<google.maps.LatLngLiteral> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  clickMap(event: google.maps.MapMouseEvent) {
    if (!this.district) return;

    this.center = event.latLng.toJSON();
    this.markerPositions = [event.latLng.toJSON()]
    this.clickMapEvent.emit(event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng.toJSON();
  }
}
