import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanillasService } from "app/core/services/recaudacion-bancaria/planillas.service";
import { columnHeader } from "app/shared/components/factoring-datatable/factoring-datatable.component";

@Component({
  selector: "app-modal-detalle-abonos",
  templateUrl: "./modal-detalle-abonos.component.html",
  styleUrls: ["./modal-detalle-abonos.component.css"],
})
export class ModalDetalleAbonosComponent implements OnInit {
  @Input() abono;
  public abonosExtornos = [];
  public abonosEstados = [];
  public abonos = [];
  columnDefs: columnHeader[];
  loadingTable: boolean = false;
  columnDefsEstado: columnHeader[];
  columnDefsExtorno: columnHeader[];
  constructor(
    private _planillasService: PlanillasService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.loadColumnsDef();
    this.getAbono();
    this.getAbonoEstadoInfo();
    this.getAbonoExtornos();
    this.loadColumnsDefEstado();
    this.loadColumnsDefExtorno();
  }

  getAbono() {
    this._planillasService
      .getAbonosInfo({ id: this.abono.id })
      .then((resp) => {
        this.abonos = resp.results;
      })
      .catch((err) => console.log(err));
  }

  getAbonoEstadoInfo() {
    this._planillasService
      .getAbonosEstados({ id: this.abono.id })
      .then((resp) => {
        this.abonosEstados = resp.results;
      })
      .catch((err) => console.log(err));
  }

  getAbonoExtornos() {
    this._planillasService
      .getAbonosExtorno({
        id: this.abono.id,
        estado__in: "2,3",
        ordering: "id",
      })
      .then((resp) => {
        this.abonosExtornos = resp.results;
      })
      .catch((err) => console.log(err));
  }

  loadColumnsDef() {
    let columns: columnHeader[] = [
      {
        class: "text-center",
        headerName: "Fecha",
        field: "fecha_creacion",
        pipe: "date",
      },
      {
        class: "text-center",
        headerName: "Usuario",
        field: "usuario_creacion",
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "nombre_cliente_anterior",
      },
      {
        class: "text-center",
        headerName: "Cliente Actualizado",
        field: "nombre_cliente_nuevo",
      },
    ];

    this.columnDefs = columns;
  }

  loadColumnsDefEstado() {
    let columns: columnHeader[] = [
      {
        class: "text-center",
        headerName: "Fecha",
        field: "fecha_creacion",
        pipe: "date",
      },
      {
        class: "text-center",
        headerName: "Usuario",
        field: "usuario_creacion",
      },
      {
        class: "text-center",
        headerName: "Estado",
        field: "tipo_estado",
      },
    ];

    this.columnDefsEstado = columns;
  }

  loadColumnsDefExtorno() {
    let columns: columnHeader[] = [
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente",
      },
      {
        class: "text-center",
        headerName: "Fecha",
        field: "fecha",
        pipe: "date",
      },
      {
        class: "text-center",
        headerName: "Estado",
        field: "estado",
      },
      {
        class: "text-center",
        headerName: "Monto Abono",

        field: "monto",
        pipe: "function",
        function: function (row: any) {
          if (row.tipo_moneda == 1) return `S/ ${row.monto.toFixed(2)}`;
          else return `$ ${row.monto.toFixed(2)}`;
        },
      },
    ];

    this.columnDefsExtorno = columns;
  }
}
