export const userPerfilesResponse: any [] = [
    {
      "id": 1,
      "nombre": "GERENTE DE SOPORTE",
      "nombre_corto": "Gte. Soporte",
      "descripcion": "Es el gerente encargado de supervisar todas las operaciones de soporte",
      "error": "Gerente no habilitado para esta operacion",
      "codigo": "1G6DV57V280235953"
    }
]

export const categoriasResponse: any [] = [
  {
    "id": 1,
    "nombre": "Extractos Bancarios",
  }, 
  {
    "id": 2,
    "nombre": "Planillas de Recaudacion",
  }, 
  {
    "id": 3,
    "nombre": "Abonos por Procesar",
  }, 
  {
    "id": 4,
    "nombre": "Aplicaciones y Extornos",
  }, 
  {
    "id": 5,
    "nombre": "Planillas no habilitadas",
  },
  {
    "id": 6,
    "nombre": "Descuentos",
  }, 
  {
    "id": 7,
    "nombre": "Clientes",
  }, 
  {
    "id": 8,
    "nombre": "Contratos CAF",
  }, 
  {
    "id": 9,
    "nombre": "Seguimientos",
  }, 
  {
    "id": 10,
    "nombre": "Multas",
  }, 
  {
    "id": 11,
    "nombre": "Visitas",
  }, 
  {
    "id": 12,
    "nombre": "Cargas Masivas",
  }, 
  {
    "id": 13,
    "nombre": "Configuración - Acciones de Cobro",
  }, 
  {
    "id": 14,
    "nombre": "Configuración - Conceptos",
  }, 
  {
    "id": 15,
    "nombre": "Configuración - Tipos de Cambios",
  },
  {
    "id": 16,
    "nombre": "Configuración - Faltas",
  },
  {
    "id": 17,
    "nombre": "Configuración - Entidades",
  },
  {
    "id": 18,
    "nombre": "Configuración - Bancos",
  },
  {
    "id": 19,
    "nombre": "Configuración - Cuentas Bancarias",
  },
]



export const accionesMock = [
  {
    "id": 1,
    "nombre": "Extornar",
    "descripcion": "Habilita el boton de extornar en la pestaña de aplicaciones en la bandeja de aplicaciones y extornos",
    "codigo": "EXTO-1",
    "categoria": 4
  }, 
  {
    "id": 2,
    "nombre": "Cargar Excel",
    "descripcion": "Habilita la carga de archivos Excel en la bandeja de Extractos Bancarios",
    "codigo": "BTN-CARG-EXC-1",
    "categoria": 2
  }, 
  {
    "id": 3,
    "nombre": "Descargar Excel",
    "descripcion": "Permite la descarga de archivos Excel en la bandeja de Extractos Bancarios",
    "codigo": "BTN-DESC-EXC-1",
    "categoria": 1
  }, 
  {
    "id": 4,
    "nombre": "Carga Manual Planilla Recaudacion",
    "descripcion": "Habilita el boton de carga manual de planillas de recaudacion",
    "codigo": "BTN-CARG-REC-1",
    "categoria": 2
  }, 
  {
    "id": 5,
    "nombre": "Ver Detalle Planilla",
    "descripcion": "Permite Activar el boton de vista de planilla de recaudacion en la bandeja de planillas de recaudacion",
    "codigo": "VER-PLN-REC-1",
    "categoria": 2
  }, 
  {
    "id": 6,
    "nombre": "Cerrar Planilla",
    "descripcion": "Permite habilitar el boton de cerrar planillas de recaudacion",
    "codigo": "CER-PLN-REC-1",
    "categoria": 2
  }, 
  {
    "id": 7,
    "nombre": "Ver Abonos Por Procesar",
    "descripcion": "Permite visualizar la pestaña de abonos por procesar en la bandeja de Abonos",
    "codigo": "VER-ABO-PRO-1",
    "categoria": 3
  }, 
  {
    "id": 8,
    "nombre": "Ver Abonos Por Identificar",
    "descripcion": "Permite visualizar la pestaña de abonos por identificar en la bandeja de Abonos",
    "codigo": "VER-ABO-IDEN-1",
    "categoria": 3
  }, 
  {
    "id": 9,
    "nombre": "Identificar Abono en abonos por identificar",
    "descripcion": "Habilita el boton de identificar abonos en la bandeja de Abonos por identificar",
    "codigo": "IDE-ABO-IDENT-1",
    "categoria": 3
  }, 
  {
    "id": 10,
    "nombre": "Ver pestaña de Aplicaciones",
    "descripcion": "Habilita la pestaña de aplicaciones en la bandeja de Aplicaciones y Extornos",
    "codigo": "VER-APL-1",
    "categoria": 4
  }, 
  {
    "id": 11,
    "nombre": "Ver pestaña de extornos",
    "descripcion": "Habilita la pestaña de extornos en la bandeja de Aplicaciones y Extornos",
    "codigo": "VER-EXTO-1",
    "categoria": 4
  }, 
  {
    "id": 12,
    "nombre": "Ver detalles de extractos",
    "descripcion": "Habilita el boton de ver detalles de extractos en la bandeja de extractos bancarios",
    "codigo": "VER-DET-1",
    "categoria": 1
  },
  {
    "id": 13,
    "nombre": "Descarga de Excel Planilla Recaudacion",
    "descripcion": "Habilita el boton de descarga de Excel en la bandeja de planillas de recaudacion",
    "codigo": "BTN-DESC-REC-1",
    "categoria": 2
  },
  {
    "id": 14,
    "nombre": "Identificar Abono en Abonos por Procesar",
    "descripcion": "Habilita el boton de identificar abonos en la bandeja de Abonos por procesar",
    "codigo": "IDE-ABO-PROC-1",
    "categoria": 3
  }, 
  {
    "id": 15,
    "nombre": "Aplicar Abono en Abonos por Procesar",
    "descripcion": "Habilita el boton de aplicar abono en la tabla de la bandeja de Abonos por procesar",
    "codigo": "APL-ABO-PROC-1",
    "categoria": 3
  }, 
  {
    "id": 16,
    "nombre": "Habilitar/Inhabilitar Abono en Abonos por Procesar",
    "descripcion": "Habilita el boton de 'Habilitar/Inhabilitar' abono en la tabla de la bandeja de Abonos por procesar",
    "codigo": "INH-HAB-ABO-1",
    "categoria": 3
  }, 
  {
    "id": 17,
    "nombre": "Ver Detalle Aplicacion",
    "descripcion": "Habilita el boton de 'Ver detalle aplicacion'  en la tabla de aplicaciones la bandeja de Aplicaciones y Extornos",
    "codigo": "VER-DET-APL-1",
    "categoria": 4
  }, 
  {
    "id": 18,
    "nombre": "Ver Detalle Aplicacion Extornada",
    "descripcion": "Habilita el boton de 'Ver detalle aplicacion'  en la tabla de extornos la bandeja de Aplicaciones y Extornos",
    "codigo": "VER-DET-APL-EXT",
    "categoria": 4
  },
  {
    "id": 19,
    "nombre": "Habilitar/Inhabilitar Abono en Abonos Planillas No Habilitadas",
    "descripcion": "Habilita el boton de 'Habilitar/Inhabilitar' abono en la tabla de la bandeja de planillas no habilitadas",
    "codigo": "INH-HAB-ABO-NO-HAB",
    "categoria": 5
  },
  {
    "id": 20,
    "nombre": "Crear campaña de descuento",
    "descripcion": "Habilita el boton de 'Campañas de descuento' en la bandeja de Descuentos",
    "codigo": "CRE-CAMP-DESC",
    "categoria": 6
  },
  {
    "id": 21,
    "nombre": "Ver Detalle Descuento",
    "descripcion": "Habilita el boton de 'Ver Detalle' en la tabla de la bandeja de Descuentos",
    "codigo": "VER-DESC",
    "categoria": 6
  },
  {
    "id": 22,
    "nombre": "Editar Descuento",
    "descripcion": "Habilita el boton de 'Editar Detalle' en la tabla de la bandeja de Descuentos",
    "codigo": "EDIT-DESC",
    "categoria": 6
  },
  {
    "id": 23,
    "nombre": "Eliminar Descuento",
    "descripcion": "Habilita el boton de 'Eliminar Detalle' en la tabla de la bandeja de Descuentos",
    "codigo": "DEL-DESC",
    "categoria": 6
  },
  {
    "id": 24,
    "nombre": "Descargar Reporte de Clientes",
    "descripcion": "Habilita el boton de 'Descargar Reporte' en la bandeja de Clientes",
    "codigo": "DEL-REP-CLI",
    "categoria": 7
  },
  {
    "id": 25,
    "nombre": "Enviar Estado de Cuenta",
    "descripcion": "Habilita el boton de 'Enviar Estado de Cuenta' en la bandeja de Clientes",
    "codigo": "ENV-EST-CTA",
    "categoria": 7
  },
  {
    "id": 26,
    "nombre": "Ver Contactos",
    "descripcion": "Habilita el boton de 'Contactos' en la tabla de la bandeja de Clientes",
   "codigo": "VER-CONT-CLI",
    "categoria": 7
  },
  {
    "id": 27,
    "nombre": "Crear Contactos",
    "descripcion": "Habilita el boton de 'Nuevo Contacto' en el modal de Contactos de la bandeja de Clientes",
   "codigo": "CREA-CONT-CLI",
    "categoria": 7
  },
  {
    "id": 28,
    "nombre": "Ver Detalle Clientes",
    "descripcion": "Habilita el boton de 'Ver Detalle' en la tabla de Clientes de la bandeja de Clientes",
    "codigo": "VER-DET-CLI",
    "categoria": 7
  },
  {
    "id": 29,
    "nombre": "Ver Lista de Cafs",
    "descripcion": "Habilita la pestaña de Listado de Cafs en el model de ver detalle de cliente de la bandeja de clientes",
    "codigo": "VER-LIST-CAF-CLI",
    "categoria": 7
  },
  {
    "id": 30,
    "nombre": "Descargar Reporte de Cafs",
    "descripcion": "Habilita el boton de descarga de reporte en la bandeja de Contratos CAF",
    "codigo": "DESC-REP-CAF",
    "categoria": 8
  },
  {
    "id": 31,
    "nombre": "Cambiar estado CAF",
    "descripcion": "Habilita el boton de 'Cambiar Estado CAF' en la tabla de la bandeja de Contratos CAF",
    "codigo": "CAMB-EST-CAF",
    "categoria": 8
  },
  {
    "id": 32,
    "nombre": "Cerrar CAF",
    "descripcion": "Habilita el boton de 'Cerrar CAF' en la tabla de la bandeja de Contratos CAF",
    "codigo": "CERR-CAF",
    "categoria": 8
  },
  {
    "id": 33,
    "nombre": "Ver Detalle CAF",
    "descripcion": "Habilita el boton de 'Ver Detalle' en la tabla de la bandeja de Contratos CAF",
    "codigo": "VER-CAF",
    "categoria": 8
  },

  {
    "id": 34,
    "nombre": "Ver Detalle CAF Cerrado",
    "descripcion": "Habilita el boton de 'Ver Detalle' en la tabla de la bandeja de Contratos CAF Cerrados",
    "codigo": "VER-CAF-CERR",
    "categoria": 8
  },
  {
    "id": 35,
    "nombre": "Cambiar estado CAF Cerrado",
    "descripcion": "Habilita el boton de 'Cambiar Estado CAF' en la tabla de la bandeja de Contratos CAF Cerrados",
    "codigo": "CAMB-EST-CAF-CERR",
    "categoria": 8
  },
  {
    "id": 36,
    "nombre": "Descargar Reporte de Cafs Cerrado",
    "descripcion": "Habilita el boton de descarga de reporte en la bandeja de Contratos CAF Cerrados",
    "codigo": "DESC-REP-CAF-CERR",
    "categoria": 8
  },

  {
    "id": 37,
    "nombre": "Ver Pestaña de Llamadas Generadas",
    "descripcion": "Habilita la pestaña de Llamadas Generadas en la Bandeja de Seguimiento",
    "codigo": "VER-LLA-GEN",
    "categoria": 9
  },
  {
    "id": 38,
    "nombre": "Ver Pestaña de Llamadas en Seguimiento",
    "descripcion": "Habilita la pestaña de Llamadas en Seguimiento en la Bandeja de Seguimiento",
    "codigo": "VER-LLA-SEG",
    "categoria": 9
  },
  {
    "id": 39,
    "nombre": "Ver Pestaña de Llamadas Administrativas",
    "descripcion": "Habilita la pestaña de Llamadas Administrativas en la Bandeja de Seguimiento",
    "codigo": "VER-LLA-ADM",
    "categoria": 9
  },
  {
    "id": 40,
    "nombre": "Ver Pestaña de Llamadas Finalizadas",
    "descripcion": "Habilita la pestaña de Llamadas Administrativas en la Bandeja de Seguimiento",
    "codigo": "VER-LLA-FIN",
    "categoria": 9
  },
  {
    "id": 41,
    "nombre": "Realizar Llamadas",
    "descripcion": "Habilita la opcion de 'Realizar Llamadas' en la Bandeja de Seguimiento. Este permiso aplica para la pestaña de LLamadas Generadas, en Seguimiento, Administrativas y Llamadas finalizadas. ",
    "codigo": "LLAMAR",
    "categoria": 9
  },
  {
    "id": 42,
    "nombre": "Asignar Ejecutivo",
    "descripcion": "Habilita la opcion de 'Asignar Ejecutivo' en la Bandeja de Seguimiento. Este permiso aplica para la pestaña de LLamadas Generadas, en Seguimiento, Administrativas y Llamadas finalizadas.",
    "codigo": "ASIG-EJEC",
    "categoria": 9
  },
  {
    "id": 43,
    "nombre": "Reprogramar Llamada",
    "descripcion": "Habilita la opcion de 'Asignar Ejecutivo' en la Bandeja de Seguimiento. Este permiso aplica para la pestaña de LLamadas Generadas, en Seguimiento, Administrativas y Llamadas finalizadas.",
    "codigo": "REPROGRAMAR",
    "categoria": 9
  },
  {
    "id": 44,
    "nombre": "Finalizar Seguimiento",
    "descripcion": "Habilita la opcion de 'Finalizar Seguimiento' en la Bandeja de Seguimiento. Este permiso aplica para la pestaña de LLamadas Generadas, en Seguimiento, Administrativas y Llamadas finalizadas.",
    "codigo": "FINALIZAR",
    "categoria": 9
  },

  {
    "id": 45,
    "nombre": "Crear Nueva Multa",
    "descripcion": "Habilita el boton de 'Nueva Multa' en la Bandeja de Multas.",
    "codigo": "NUEV-MULTA",
    "categoria": 10
  },
  {
    "id": 46,
    "nombre": "Ver Multa",
    "descripcion": "Habilita la opcion de 'Ver Multa' en la Bandeja de Multas.",
    "codigo": "VER-MULTA",
    "categoria": 10
  },
  {
    "id": 47,
    "nombre": "Impugnar",
    "descripcion": "Habilita la opcion de 'Impugnar' en la Bandeja de Multas.",
    "codigo": "IMPUGNAR",
    "categoria": 10
  },
  {
    "id": 48,
    "nombre": "Apelar",
    "descripcion": "Habilita la opcion de 'Apelar' en la Bandeja de Multas.",
    "codigo": "APELAR",
    "categoria": 10
  },
  {
    "id": 49,
    "nombre": "Resolver",
    "descripcion": "Habilita la opcion de 'Apelar' en la Bandeja de Multas.",
    "codigo": "RESOLVER",
    "categoria": 10
  },

  {
    "id": 50,
    "nombre": "Ver Pestaña de Visitas Programadas",
    "descripcion": "Habilita la pestaña de 'Visitas Programadas' en la bandeja de Visitas",
    "codigo": "VER-VIS-PROG",
    "categoria": 11
  },
  {
    "id": 51,
    "nombre": "Ver Pestaña de Visitas Aprobadas",
    "descripcion": "Habilita la pestaña de 'Visitas Aprobadas' en la bandeja de Visitas",
    "codigo": "VER-VIS-APRO",
    "categoria": 11
  },
  {
    "id": 52,
    "nombre": "Ver Pestaña de Visitas Finalizadas",
    "descripcion": "Habilita la pestaña de 'Visitas Finalizadas' en la bandeja de Visitas",
    "codigo": "VER-VIS-FIN",
    "categoria": 11
  },

  {
    "id": 53,
    "nombre": "Ver Detalles de Cargas Masivas",
    "descripcion": "Habilita la opcion de 'Ver Detalle' en la bandeja de Cargas Masivas",
    "codigo": "VER-DET-CARG",
    "categoria": 12
  },
  {
    "id": 54,
    "nombre": "Nueva Carga Masiva",
    "descripcion": "Habilita el boton de 'Nueva Carga Masiva' en la bandeja de Cargas Masivas",
    "codigo": "NUEV-CARG-MAS",
    "categoria": 12
  },

  {
    "id": 55,
    "nombre": "Ver Pestaña de Acciones Preventivas",
    "descripcion": "Habilita el boton de 'Nueva Carga Masiva' en la maestra de Acciones de Cobro",
    "codigo": "VER-ACC-PREV",
    "categoria": 13
  },
  {
    "id": 56,
    "nombre": "Ver Pestaña de Acciones Administrativas",
    "descripcion": "Habilita el boton de 'Nueva Carga Masiva' en la maestra de Acciones de Cobro",
    "codigo": "VER-ACC-ADM",
    "categoria": 13
  },
  {
    "id": 57,
    "nombre": "Editar Acciones",
    "descripcion": "Habilita el boton de 'Editar' en las tablas de la Maestra de Acciones de Cobro",
    "codigo": "EDI-ACC-COBR",
    "categoria": 13
  },

  {
    "id": 58,
    "nombre": "Nuevo Concepto",
    "descripcion": "Habilita el boton de 'Nuevo Concepto' en las tablas de la Maestra de Conceptos",
    "codigo": "CRE-CONCEP",
    "categoria": 14
  },
  {
    "id": 59,
    "nombre": "Cambiar Prioridad",
    "descripcion": "Habilita los botones de aumentar/disminuir prioridad en las tabla de la Maestra de Conceptos",
    "codigo": "CAMB-CONCEP",
    "categoria": 14
  },
  {
    "id": 60,
    "nombre": "Editar Concepto",
    "descripcion": "Habilita los botones de aumentar/disminuir prioridad en las tabla de la Maestra de Conceptos",
    "codigo": "EDIT-CONCEP",
    "categoria": 14
  },
  {
    "id": 61,
    "nombre": "Eliminar Concepto",
    "descripcion": "Habilita los botones de eliminar concepto en las tabla de la Maestra de Conceptos",
    "codigo": "DEL-CONCEP",
    "categoria": 14
  },
  {
    "id": 62,
    "nombre": "Editar Tipo de Cambio",
    "descripcion": "Habilita los botones de 'Editar tipo de Cambio' en la tabla de la bandeja de tipos de Cambio.",
    "codigo": "EDIT-TIP-CAMB",
    "categoria": 15
  },
  {
    "id": 63,
    "nombre": "Eliminar Tipo de Cambio",
    "descripcion": "Habilita los botones de 'Eliminar tipo de Cambio' en la tabla de la bandeja de tipos de Cambio.",
    "codigo": "DEL-TIP-CAMB",
    "categoria": 15
  },
  {
    "id": 64,
    "nombre": "Crear Nuevo Tipo de Cambio",
    "descripcion": "Habilita el boton de 'Nuevo tipo de Cambio' en la bandeja de tipos de Cambio.",
    "codigo": "NUEV-TIP-CAMB",
    "categoria": 15
  },

  {
    "id": 65,
    "nombre": "Crear Nueva Falta",
    "descripcion": "Habilita el boton de 'Nueva Falta' en la bandeja de Faltas.",
    "codigo": "NUEV-FALT",
    "categoria": 16
  },
  {
    "id": 66,
    "nombre": "Editar Falta",
    "descripcion": "Habilita el boton de 'Editar Falta' en la bandeja de Faltas.",
    "codigo": "EDIT-FALT",
    "categoria": 16
  },
  {
    "id": 67,
    "nombre": "Eliminar Falta",
    "descripcion": "Habilita el boton de 'Eliminar Falta' en la bandeja de Faltas.",
    "codigo": "DEL-FALT",
    "categoria": 16
  },

  {
    "id": 68,
    "nombre": "Crear Nueva Entidad",
    "descripcion": "Habilita el boton de 'Nueva Entidad' en la bandeja de Entidades.",
    "codigo": "NUEV-ENTI",
    "categoria": 17
  },
  {
    "id": 69,
    "nombre": "Editar Entidad",
    "descripcion": "Habilita el boton de 'Nueva Entidad' en la bandeja de Entidades.",
    "codigo": "EDIT-ENTI",
    "categoria": 17
  },
  {
    "id": 70,
    "nombre": "Eliminar Entidad",
    "descripcion": "Habilita el boton de 'Eliminar Entidad' en la bandeja de Entidades.",
    "codigo": "DEL-ENTI",
    "categoria": 17
  },
  {
    "id": 71,
    "nombre": "Editar Banco",
    "descripcion": "Habilita el boton de 'Eliminar Banco' en la bandeja de Bancos.",
    "codigo": "EDIT-BANC",
    "categoria": 18
  },
  {
    "id": 72,
    "nombre": "Nueva Cuenta",
    "descripcion": "Habilita el boton de 'Nueva Cuenta' en la bandeja de Cuentas Bancarias.",
    "codigo": "NUEV-CTA-BANC",
    "categoria": 19
  },
  {
    "id": 73,
    "nombre": "Editar Cuenta",
    "descripcion": "Habilita el boton de 'Editar Cuenta' en la bandeja de Cuentas Bancarias.",
    "codigo": "EDIT-CTA-BANC",
    "categoria": 19
  },
  {
    "id": 74,
    "nombre": "Eliminar Cuenta",
    "descripcion": "Habilita el boton de 'Eliminar Cuenta' en la bandeja de Cuentas Bancarias.",
    "codigo": "DEL-CTA-BANC",
    "categoria": 19
  },
]

export const maestraPerfiles = [
  {
    "id": 1,
    "nombre": "GERENTE DE SOPORTE",
    "nombre_corto": "Gte. Soporte",
    "descripcion": "Es el gerente encargado de supervisar todas las operaciones de soporte",
    "error": "Gerente no habilitado para esta operacion",
    "codigo": "1G6DV57V280235953"
  }, {
    "id": 2,
    "nombre": "Estimador",
    "nombre_corto": "Surveyor",
    "descripcion": "eget massa tempor convallis nulla neque libero convallis eget eleifend luctus ultricies eu nibh quisque id justo sit",
    "error": "ac neque duis bibendum",
    "codigo": "WAUDH98E46A853810"
  }, {
    "id": 3,
    "nombre": "Supervisor",
    "nombre_corto": "Construction Expeditor",
    "descripcion": "dolor quis odio consequat varius integer ac leo pellentesque ultrices mattis odio",
    "error": "sit amet eleifend",
    "codigo": "WBAGK93491D212317"
  }, {
    "id": 4,
    "nombre": "Oficial de NEgocio",
    "nombre_corto": "Supervisor",
    "descripcion": "lacus purus aliquet at feugiat non pretium quis lectus suspendisse potenti in eleifend quam",
    "error": "eu est",
    "codigo": "2T2BC1BA7FC451786"
  }, {
    "id": 5,
    "nombre": "Arquitecto de Sistema",
    "nombre_corto": "Supervisor",
    "descripcion": "at nulla suspendisse potenti cras in purus eu magna vulputate luctus cum sociis natoque penatibus et",
    "error": "in faucibus orci",
    "codigo": "WP1AA2A23EL434587"
  }
]

export const relacionesMain =[
  {
    "id": 1,
    "id_perfil": 1,
    "id_accion": 2
  }, {
    "id": 2,
    "id_perfil": 2,
    "id_accion": 3
  }, {
    "id": 3,
    "id_perfil": 1,
    "id_accion": 4
  }, {
    "id": 4,
    "id_perfil": 2,
    "id_accion": 20
  }, 
  {
    "id": 5,
    "id_perfil": 1,
    "id_accion": 20
  }, {
    "id": 6,
    "id_perfil": 2,
    "id_accion": 15
  }, {
    "id": 7,
    "id_perfil": 2,
    "id_accion": 33
  }, {
    "id": 8,
    "id_perfil": 2,
    "id_accion": 5
  }, {
    "id": 9,
    "id_perfil": 1,
    "id_accion": 32
  }, {
    "id": 10,
    "id_perfil": 2,
    "id_accion": 11
  }, {
    "id": 11,
    "id_perfil": 1,
    "id_accion": 20
  }, {
    "id": 12,
    "id_perfil": 2,
    "id_accion": 20
  }, {
    "id": 13,
    "id_perfil": 1,
    "id_accion": 14
  }, {
    "id": 14,
    "id_perfil": 2,
    "id_accion": 20
  }, {
    "id": 15,
    "id_perfil": 1,
    "id_accion": 33
  }, {
    "id": 16,
    "id_perfil": 2,
    "id_accion": 15
  }, {
    "id": 17,
    "id_perfil": 2,
    "id_accion": 14
  }, {
    "id": 18,
    "id_perfil": 2,
    "id_accion": 20
  }, {
    "id": 19,
    "id_perfil": 1,
    "id_accion": 6
  }, {
    "id": 20,
    "id_perfil": 2,
    "id_accion": 20
  }, {
    "id": 21,
    "id_perfil": 1,
    "id_accion": 10
  }, {
    "id": 22,
    "id_perfil": 2,
    "id_accion": 5
  }, {
    "id": 23,
    "id_perfil": 1,
    "id_accion": 10
  },
  {
    "id": 24,
    "id_perfil": 8,
    "id_accion": 7
  }, {
    "id": 25,
    "id_perfil": 15,
    "id_accion": 11
  }, {
    "id": 26,
    "id_perfil": 2,
    "id_accion": 13
  }, {
    "id": 27,
    "id_perfil": 10,
    "id_accion": 13
  }, {
    "id": 28,
    "id_perfil": 8,
    "id_accion": 15
  }, {
    "id": 29,
    "id_perfil": 5,
    "id_accion": 13
  }, {
    "id": 30,
    "id_perfil": 14,
    "id_accion": 12
  }, {
    "id": 31,
    "id_perfil": 15,
    " ": 6
  }, {
    "id": 32,
    "id_perfil": 10,
    "id_accion": 13
  }, {
    "id": 33,
    "id_perfil": 14,
    "id_accion": 2
  }, {
    "id": 34,
    "id_perfil": 2,
    "id_accion": 1
  }, {
    "id": 35,
    "id_perfil": 2,
    "id_accion": 7
  }, {
    "id": 36,
    "id_perfil": 15,
    "id_accion": 10
  }, {
    "id": 37,
    "id_perfil": 4,
    "id_accion": 7
  }, {
    "id": 38,
    "id_perfil": 8,
    "id_accion": 9
  }, {
    "id": 39,
    "id_perfil": 5,
    "id_accion": 13
  }, {
    "id": 40,
    "id_perfil": 14,
    "id_accion": 7
  }, {
    "id": 41,
    "id_perfil": 1,
    "id_accion": 15
  }, {
    "id": 42,
    "id_perfil": 2,
    "id_accion": 7
  }, {
    "id": 43,
    "id_perfil": 9,
    "id_accion": 11
  }, {
    "id": 44,
    "id_perfil": 12,
    "id_accion": 10
  }, {
    "id": 45,
    "id_perfil": 3,
    "id_accion": 8
  }, {
    "id": 46,
    "id_perfil": 13,
    "id_accion": 14
  }, {
    "id": 47,
    "id_perfil": 3,
    "id_accion": 3
  }, {
    "id": 48,
    "id_perfil": 6,
    "id_accion": 5
  }, {
    "id": 49,
    "id_perfil": 7,
    "id_accion": 5
  }, {
    "id": 50,
    "id_perfil": 11,
    "id_accion": 7
  }, {
    "id": 51,
    "id_perfil": 4,
    "id_accion": 4
  }, {
    "id": 52,
    "id_perfil": 6,
    "id_accion": 3
  }, {
    "id": 53,
    "id_perfil": 13,
    "id_accion": 2
  }, {
    "id": 54,
    "id_perfil": 7,
    "id_accion": 8
  }, {
    "id": 55,
    "id_perfil": 11,
    "id_accion": 9
  }, {
    "id": 56,
    "id_perfil": 15,
    "id_accion": 12
  }, {
    "id": 57,
    "id_perfil": 14,
    "id_accion": 7
  }, {
    "id": 58,
    "id_perfil": 7,
    "id_accion": 15
  }, {
    "id": 59,
    "id_perfil": 12,
    "id_accion": 5
  }, {
    "id": 60,
    "id_perfil": 5,
    "id_accion": 10
  }, {
    "id": 61,
    "id_perfil": 3,
    "id_accion": 13
  }, {
    "id": 62,
    "id_perfil": 15,
    "id_accion": 12
  }, {
    "id": 63,
    "id_perfil": 10,
    "id_accion": 11
  }, {
    "id": 64,
    "id_perfil": 7,
    "id_accion": 11
  }, {
    "id": 65,
    "id_perfil": 4,
    "id_accion": 4
  }, {
    "id": 66,
    "id_perfil": 10,
    "id_accion": 4
  }, {
    "id": 67,
    "id_perfil": 7,
    "id_accion": 10
  }, {
    "id": 68,
    "id_perfil": 13,
    "id_accion": 11
  }, {
    "id": 69,
    "id_perfil": 10,
    "id_accion": 10
  }, {
    "id": 70,
    "id_perfil": 15,
    "id_accion": 3
  }, {
    "id": 71,
    "id_perfil": 14,
    "id_accion": 3
  }, {
    "id": 72,
    "id_perfil": 1,
    "id_accion": 11
  }, {
    "id": 73,
    "id_perfil": 13,
    "id_accion": 14
  }, {
    "id": 74,
    "id_perfil": 14,
    "id_accion": 11
  }, {
    "id": 75,
    "id_perfil": 8,
    "id_accion": 13
  }, {
    "id": 76,
    "id_perfil": 1,
    "id_accion": 7
  }, {
    "id": 77,
    "id_perfil": 4,
    "id_accion": 10
  }, {
    "id": 78,
    "id_perfil": 10,
    "id_accion": 13
  }, {
    "id": 79,
    "id_perfil": 10,
    "id_accion": 10
  }, {
    "id": 80,
    "id_perfil": 4,
    "id_accion": 11
  }, {
    "id": 81,
    "id_perfil": 6,
    "id_accion": 15
  }, {
    "id": 82,
    "id_perfil": 12,
    "id_accion": 1
  }, {
    "id": 83,
    "id_perfil": 3,
    "id_accion": 11
  }, {
    "id": 84,
    "id_perfil": 1,
    "id_accion": 11
  }, {
    "id": 85,
    "id_perfil": 9,
    "id_accion": 8
  }, {
    "id": 86,
    "id_perfil": 9,
    "id_accion": 14
  }, {
    "id": 87,
    "id_perfil": 9,
    "id_accion": 8
  }, {
    "id": 88,
    "id_perfil": 9,
    "id_accion": 14
  }, {
    "id": 89,
    "id_perfil": 13,
    "id_accion": 10
  }, {
    "id": 90,
    "id_perfil": 3,
    "id_accion": 15
  }, {
    "id": 91,
    "id_perfil": 15,
    "id_accion": 5
  }, {
    "id": 92,
    "id_perfil": 4,
    "id_accion": 10
  }, {
    "id": 93,
    "id_perfil": 13,
    "id_accion": 10
  }, {
    "id": 94,
    "id_perfil": 8,
    "id_accion": 3
  }, {
    "id": 95,
    "id_perfil": 7,
    "id_accion": 14
  }, {
    "id": 96,
    "id_perfil": 3,
    "id_accion": 11
  }, {
    "id": 97,
    "id_perfil": 5,
    "id_accion": 15
  }, {
    "id": 98,
    "id_perfil": 14,
    "id_accion": 2
  }, {
    "id": 99,
    "id_perfil": 8,
    "id_accion": 13
  }, {
    "id": 100,
    "id_perfil": 5,
    "id_accion": 2
}]