import { AuthenticationService } from "./../authentication/authentication.service";
import { LocalServiceService } from "./../local-service.service";
import { relacionesMain } from "./../authentication/authentication.mocks";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";
import { ToastService } from "../toast/toast.service";
import { environment } from "environments/environment";
import { map } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import {
  Cliente,
  ClientesResponse,
} from "../../../shared/interfaces/clientes.interface";
import { SpinnerService } from "../spinner.service";
import {
  accionesMock,
  maestraPerfiles,
} from "../authentication/authentication.mocks";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class ClientesService {
  private apiUrl: string = "";
  public clientes: string = "/clientes/clientes/";
  public ejecutivos: string = "/ejecutivos/ejecutivos/";
  public contactos: string = "/clientes/contactos/";

  constructor(
    public appService: AppService,
    public toast: ToastService,
    private spinnerService: SpinnerService,
    public http: HttpClient,
    public localService: LocalServiceService,
    public authenticacion: AuthenticationService
  ) {
    this.apiUrl = environment.API_URL;
  }

  deletePerfil(idPerfil: number) {
    const url = `${this.apiUrl}/perfiles/perfiles/${idPerfil}/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.delete(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff("Se eliminó perfil con éxito");
          resolve(resp);
        },
        ({ error }) => {
          if (error.mensaje) {
            this.spinnerService.spinnerOff(error.mensaje, false);
          } else {
            this.spinnerService.spinnerOff("Algo salió mal", false);
          }
          reject(error);
        }
      );
    });
  }

  getReportePagador(clienteID: string) {
    const url = `${this.apiUrl}/clientes/clientes/${clienteID}/estado_pagador/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url, { observe: "response", responseType: "blob" as "json" })
        .subscribe(
          (data) => {
            this.spinnerService.spinnerOff();
            resolve(data);
          },
          async (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  getExcellClientes(filtros) {
    var url = `${this.apiUrl}/clientes/clientes/descargar_excel/?`;
    const params = new HttpParams();

    if (Object.keys(filtros).length > 0) {
      for (let name in filtros) {
        url += `${name}=${filtros[name]}&`;
      }
    }
    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url, { observe: "response", responseType: "blob" as "json" })
        .subscribe(
          (data) => {
            this.spinnerService.spinnerOff();
            resolve(data);
          },
          async (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  putMulta(idMulta: number, data: any) {
    const url = `${this.apiUrl}/entidades/multa/${idMulta}/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.patch<any>(url, data).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff("Se actualizó papeleta con éxito");
          resolve(resp);
        },
        (err) => {
          if (err.error && err.error.Error) {
            this.spinnerService.spinnerOff(err.error.Error, false);
          } else {
            this.spinnerService.spinnerOff("Algo salió mal", false);
          }
          reject(err);
        }
      );
    });
  }

  obtenerEjecutivos(page_size: number = 100) {
    const url = this.apiUrl + this.ejecutivos + `?page_size=${page_size}`;

    return new Promise((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff(
            "La operación falló al traer los ejecutivos",
            false
          );
          reject(err);
        }
      );
    });
  }

  obtenerClientes(
    page: number = 1,
    page_size: number = 10,
    ruc__icontains: string = "",
    grupo: string = "",
    nombre__icontains: any = ""
  ) {
    const url =
      this.apiUrl +
      this.clientes +
      `?nombre__icontains=${nombre__icontains}` +
      `&grupo=${grupo}` +
      `&ruc__icontains=${ruc__icontains}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();

      this.http.get(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff(
            "La operación falló al traer los clientes",
            false
          );
          reject(err);
        }
      );
    });
  }

  obtenerPerfiles(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      "/perfiles/perfiles/" +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise<any>((resolve, reject) => {
      // this.spinnerService.spinnerOn();

      // resolve(this.localService.getJsonValue('maestraPerfiles'));

      this.http.get(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp["results"]);
        },
        (err) => {
          this.spinnerService.spinnerOff(
            "La operación falló al traer los clientes",
            false
          );
          reject(err);
        }
      );
    });
  }

  actualizarPerfilesAccion(perfilID, body) {
    const url = this.apiUrl + `/perfiles/perfiles/${perfilID}/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();

      this.http.patch(url, body).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff(
            "La operación falló al traer los clientes",
            false
          );
          this.spinnerService.spinnerOff();

          reject(err);
        }
      );
    });
  }

  actualizarPerfilesUsuario(perfilID, body) {
    const url = this.apiUrl + `/ejecutivos/ejecutivos/${perfilID}/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();

      this.http.patch(url, body).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff(
            "La operación falló al traer los clientes",
            false
          );
          reject(err);
        }
      );
    });
  }

  obtenerAccionesPerfiles(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      "/perfiles/perfiles/?" +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      // resolve(this.localService.getJsonValue('relacionesMain'));

      this.http.get(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp["results"]);
        },
        (err) => {
          this.spinnerService.spinnerOff(
            "La operación falló al traer los clientes",
            false
          );
          reject(err);
        }
      );
    });
  }

  obtenerCategoriasAcciones(page: number = 1, page_size: number = 10) {
    const url =
      this.apiUrl +
      "/perfiles/categorias/?" +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      // resolve(this.localService.getJsonValue('categoriasResponse'));

      this.http.get(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp["results"]);
        },
        (err) => {
          this.spinnerService.spinnerOff(
            "La operación falló al traer los clientes",
            false
          );
          reject(err);
        }
      );
    });
  }

  updateAccionPerfil({ value, id_accion, id_perfil }) {
    const url = this.apiUrl + this.clientes;
    return new Promise<any>((resolve, reject) => {
      // this.spinnerService.spinnerOn();
      // var relaciones = this.localService.getJsonValue('relacionesMain')
      // if(value){
      //   relaciones.push({id: relaciones.length,id_accion,id_perfil})
      // } else {
      //   const items = relaciones.filter((rel) => rel.id_accion === id_accion &&  rel.id_perfil === id_perfil)
      //   items.forEach((item)=>{
      //     const index = relaciones.findIndex( rel => rel.id === item.id);
      //     relaciones.splice(index, 1)
      //   })
      // }
      // this.localService.setJsonValue('relacionesMain',relaciones)
      // this.authenticacion.updatePerfilesPermissions().subscribe(res => console.log)
      // this.http.get(url).subscribe(
      //   (resp) => {
      //     this.spinnerService.spinnerOff();
      //     resolve(resp);
      //   },
      //   (err) => {
      //     this.spinnerService.spinnerOff("La operación falló al traer los clientes", false);
      //     reject(err);
      //   }
      // );
    });
  }

  createNuevoGrupoSeguridad(grupodata) {
    const url = this.apiUrl + `/perfiles/perfiles/`;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, grupodata).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff(
            "La operación falló al traer los clientes",
            false
          );
          reject(err);
        }
      );
    });
  }

  obtenerAcciones(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      "/perfiles/acciones/?" +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      // resolve(this.localService.getJsonValue('accionesMock'))
      this.http.get(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp["results"]);
        },
        (err) => {
          this.spinnerService.spinnerOff(
            "La operación falló al traer los clientes",
            false
          );
          reject(err);
        }
      );
    });
  }

  /**
   * Retorna los clientes
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   * @param oficial_negocio__icontains
   * @param nombre__icontains
   * @param sector
   * @param ruc__icontains
   */
  obtenerClientesObservable(
    page: number = 1,
    page_size: number = 10,
    ruc__icontains: string = "",
    nombre__icontains: any = "",
    grupo: string = ""
  ): Observable<any> {
    const url =
      this.apiUrl +
      this.clientes +
      `?nombre__icontains=${nombre__icontains}` +
      `&grupo=${grupo}` +
      `&ruc__icontains=${ruc__icontains}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return this.http.get<any>(url).pipe(
      map((resp) => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.results;
        }
      })
    );
  }

  guardarCliente(data: any, clienteId: number) {
    const url = this.apiUrl + this.clientes + `${clienteId}/`;

    return new Promise((res, ref) => {
      this.spinnerService.spinnerOn();
      this.http.patch(url, data).subscribe(
        (response) => {
          this.spinnerService.spinnerOff(
            "La información fue guardada exitosamente."
          );
          res(response);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          ref(err);
        }
      );
    });
  }

  agregarCliente(data: any) {
    const url = this.apiUrl + this.clientes;

    return new Promise((res, ref) => {
      this.spinnerService.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerService.spinnerOff(
            "La información fue guardada exitosamente."
          );
          res(response);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          ref(err);
        }
      );
    });
  }

  obtenerContactosxCliente(
    idCliente: number,
    page: number = 1,
    page_size: number = 5,
    nombre__icontains: string = "",
    apellidos__icontains: string = "",
    contacto_principal: string = "",
    cargo__icontains: string = ""
  ) {
    const url =
      this.apiUrl +
      this.contactos +
      `?cliente=${idCliente}` +
      `&page_size=${page_size}` +
      `&page=${page}` +
      `&nombre__icontains=${nombre__icontains}` +
      `&apellidos__icontains=${apellidos__icontains}` +
      `&contacto_principal=${contacto_principal}` +
      `&cargo__icontains=${cargo__icontains}`;

    return new Promise<any>((res, ref) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url)
        .pipe(
          map((resp: any) => {
            resp.results.map((item: any) => {
              item.fullInfo = `${item.nombre} ${item.apellidos} - ${item.celular1}`;
              return item;
            });

            return resp;
          })
        )
        .subscribe(
          (response) => {
            this.spinnerService.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            ref(err);
          }
        );
    });
  }

  obtenerClientesObserver(
    ruc_nombre__icontains,
    page: number = 1,
    page_size: number = 10000
  ): Observable<Cliente[]> {
    const URL =
      this.apiUrl +
      this.clientes +
      `?ruc_nombre__icontains=${ruc_nombre__icontains}` +
      `&page=${page}` +
      `&page_size=${page_size}`;

    return this.http.get<ClientesResponse>(URL).pipe(
      map((resp) => {
        if (!resp) {
          throwError("Error");
        } else {
          return resp.results;
        }
      })
    );

    // Original
    // return this.http.get<any>(URL)
    //   .pipe(map(resp => {
    //       if (resp.Error) {
    //         throwError(resp.Error);
    //       } else {
    //         return resp.results;
    //       }
    //     })
    //   );
  }

  buscarCliente(ruc_nombre__icontains?: "") {
    const url = `${this.apiUrl}/clientes/clientes/`;
    const params = new HttpParams()
      .set("page", "1")
      .set("page_size", "100")
      .set("ruc_nombre__icontains", ruc_nombre__icontains);

    return new Promise<Cliente[]>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get<ClientesResponse>(url, { params })
        .pipe(
          map((resp) => {
            const clientes = resp.results.map((cliente) => {
              return {
                ...cliente,
                fullsearch: cliente.nombre + cliente.ruc,
              };
            });

            return clientes;
          })
        )
        .subscribe(
          (resp) => {
            this.spinnerService.spinnerOff();
            resolve(resp);
          },
          (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  buscarClientePorReferencia(idAbono) {
    const url = `${this.apiUrl}/clientes/clientes/busqueda_similares/?referencia=${idAbono}`;

    return new Promise((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  saveClienteIdentificado(cliente: any, data: any) {
    const url = `${this.apiUrl}/planillas/identificacion-abono/?cliente=${cliente}`;

    return new Promise<Cliente>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.post<Cliente>(url, data).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff(
            "Abono ha sido identificado con éxito"
          );
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  validarPeriodo(periodo) {
    const url = `${this.apiUrl}/contratos/cierres/?periodo=${periodo}`;

    return new Promise<Cliente>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<Cliente>(url).subscribe(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  saveCliente(cliente: any, data: any) {
    const url = `${this.apiUrl}/extractos/transacciones/${cliente}/identificar_cliente/`;

    return new Promise<Cliente>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.post<Cliente>(url, data).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff(
            "El cliente ha sido identificado con éxito"
          );
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  saveSimilares(idTransaccion: number) {
    const url = `${this.apiUrl}/extractos/transacciones/${idTransaccion}/identificar_cliente_misma_descripcion/`;

    return new Promise<Cliente>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.post<Cliente>(url, "").subscribe(
        (resp) => {
          this.spinnerService.spinnerOff(
            "El cliente ha sido identificado con éxito"
          );
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getCliente(clienteID: number) {
    const url = `${this.apiUrl}/clientes/clientes/${clienteID}/`;

    return new Promise<Cliente>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<Cliente>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getClienteListadoCorreos(clienteID: number) {
    const url = `${this.apiUrl}/clientes/clientes/${clienteID}/listado_correos_cliente/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get<any>(url)
        .pipe(
          map((resp) => {
            const emails = [...resp.lista_destinatarios];
            const filteredEmails = emails.filter(
              (correo) => correo !== "" && correo !== null
            );
            return { lista_destinatarios: filteredEmails };
          })
        )
        .subscribe(
          (resp) => {
            this.spinnerService.spinnerOff();
            resolve(resp);
          },
          (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  enviarEstadosCuentaMasivos(
    clientes: Readonly<number[]>,
    enviar_abonos: boolean
  ) {
    const url = `${this.apiUrl}/contratos/contratos/enviar_estado_cuenta_masivo/`;
    const body = { clientes, enviar_abonos };

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.post<any>(url, body).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          if (err.error && err.error.Error) {
            this.spinnerService.spinnerOff(err.error.Error, false);
          } else {
            this.spinnerService.spinnerOff("Algo salió mal", false);
          }
          reject(err);
        }
      );
    });
  }

  getEstadosCuentaEnviados(page: number = 1, page_size: number = 10) {
    const url = `${this.apiUrl}/clientes/reportes_estado_cuenta/`;
    const params = new HttpParams()
      .set("page", page)
      .set("page_size", page_size);

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<any>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getClientesByEstadoCuentaEnviado(
    page: number = 1,
    page_size: number = 10,
    id_reporte_estado_cuenta: number
  ) {
    const url = `${this.apiUrl}/clientes/clientes/`;
    const params = new HttpParams()
      .set("page", page)
      .set("page_size", page_size)
      .set("reportes_estado_cuenta", id_reporte_estado_cuenta);

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<any>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getTotalPagosCliente(clienteID: number) {
    const url = `${this.apiUrl}/clientes/clientes/${clienteID}/detalle_cliente`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<any>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  downloadPDFEstadoCuenta(clienteID: number) {
    const url = `${this.apiUrl}/clientes/clientes/${clienteID}/descargar_estado_cuenta_cliente_pdf/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url, { observe: "response", responseType: "blob" })
        .subscribe(
          (resp) => {
            this.spinnerService.spinnerOff();
            resolve(resp);
          },
          (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  downloadPDFComportamientoPago(clienteID: number) {
    const url = `${this.apiUrl}/clientes/clientes/${clienteID}/estado_pagador/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url, { observe: "response", responseType: "blob" })
        .subscribe(
          (resp) => {
            this.spinnerService.spinnerOff();
            resolve(resp);
          },
          (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }
}
