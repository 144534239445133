import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
// import { browser } from 'protractor';

@Injectable({
	providedIn: 'root'
})
export class AppService {
	private configSettings: any = null;

	configUrl = `assets/ENV.json`;

	constructor(private http: HttpClient) { }

	get settings() {
		return this.configSettings;
	}

	public load(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.http.get(this.configUrl).subscribe((response: any) => {
				this.configSettings = response;
				if (!environment.production) {
					this.configSettings.API_base_url = 'https://cobranzas-dev-back.totalsf.com.pe/'
				}
				resolve(true);
			}, (err) => {
				reject(err)
			});
		});
	}

	notifyMe(msg = null, ok = true) {

		if (!msg) {
			return
		}


		if (!("Notification" in window)) {
			alert("Este navegador no soporta las notificaciones del sistema");
		}

		// Comprobamos si ya nos habían dado permiso
		else if (Notification.permission === "granted") {
			// Si esta correcto lanzamos la notificación
			this.displayNotif(msg)

		}

		// Si no, tendremos que pedir permiso al usuario
		else if (Notification.permission !== 'denied') {
			Notification.requestPermission(function (permission) {
				// Si el usuario acepta, lanzamos la notificación
				if (permission === "granted") {
					// var notification = new Notification(msg);
					this.displayNotif(msg)
				}
			});
		}
	}

	displayNotif(msg, ok = true) {
		// console.log()
		let notif = {
			body: msg,
			title: this.settings.tituloNotificaciones,
			icon: this.settings.iconoFromAssets
		}

		var notification = new Notification('Factoring Total', notif);

		setTimeout(() => {
			notification.close()
		}, this.settings.notificationsTime)
	}
}
