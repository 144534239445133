import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'downloadLink'
})
export class DownloadLinkPipe implements PipeTransform {

  private _apiUrl: string = environment.API_URL;

  transform(value: string): string {
    if (!value) return;

    return `${this._apiUrl}${value}`;
  }

}
