import { Component, Input, OnInit } from '@angular/core';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { SpinnerService } from 'app/core/services/spinner.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CafsService } from 'app/core/services/recaudacion-bancaria/cafs.service';
import { TiposCambioService } from 'app/core/services/configuracion/tipos-cambio.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-descuento-interes',
  templateUrl: './modal-descuento-interes.component.html',
  styleUrls: ['./modal-descuento-interes.component.css']
})
export class ModalDescuentoInteresComponent implements OnInit {
  form: FormGroup;
  identidadCliente: any;
  pago_resolucion: any[] = [
    {
      id: 1,
      descripcion: 'Pago Cliente'
    },
    {
      id: 2,
      descripcion: 'Pago TSF'
    },
  ];

  constructor(
    // tslint:disable-next-line: typedef-whitespace
    public  activeModal: NgbActiveModal,
    // tslint:disable-next-line: typedef-whitespace
    private fb: FormBuilder,
    // tslint:disable-next-line: typedef-whitespace
    public cafService: CafsService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      descuento: [0, [Validators.required, Validators.min(0), Validators.max(100)]]
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { descuento } = this.form.value;
    const transformedDiscount = Number(descuento) / 100;
    this.activeModal.close(transformedDiscount);
  }

  get descuentoErrorMessage() {
    const errors = this.form.get('descuento').errors;
    if (errors.max) {
      return 'Valor máx. debe ser 100%';
    } else if (errors.min) {
      return 'Valor mín. debe ser 0%';
    } else {
      return 'El campo es requerido';
    }
  }
}
