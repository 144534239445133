import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-vistaprevia',
  templateUrl: './vistaprevia.component.html',
  styleUrls: ['./vistaprevia.component.css']
})
export class VistapreviaComponent implements OnInit {
  public previSelector: HTMLElement = document.querySelector("#vistaprevia");
  public homeSelector: HTMLElement = document.querySelector("#home-section");
  public isOpen: boolean;
  objvistaprevia: any;
  @ContentChild('barravistaprevia') barravistaprevia: TemplateRef<any>; 

  @Input() set datapreview(value : any){
    console.log(value)
    if(value){
      let stringedValue = JSON.stringify(value);
      let stringedObj = JSON.stringify(this.objvistaprevia);
      if((stringedValue != stringedObj) && this.isOpen){
        this.previToggle()
        setTimeout(()=>{
          this.previToggle()
        },300)
      }
    }
    this.objvistaprevia = value
  }

  get datapreview(){
    return this.objvistaprevia
  }

  constructor() { }

  ngOnDestroy(){
    if(this.isOpen){
      this.previToggle()
      let homeSection       = document.querySelector("#home-section");
      homeSection.classList.remove("wraped-left");
      homeSection.classList.remove("width-both-sides");
    }
  }

  ngOnInit(): void {
  }

  previToggle(){
    if(this.isOpen){
      this.closePrevi()
    } else {
      this.openPrevi()
    }
  }

  openPrevi(){
    let previSection = document.querySelector("#vistaprevia");
    previSection.classList.add("previ-open");
    this.homeSelector.classList.add('wraped-right')
    this.homeSelector.classList.add('width-right')
    this.isOpen = true
  }

  closePrevi(){
    let previSection = document.querySelector("#vistaprevia");
    previSection.classList.remove("previ-open");
    this.homeSelector.classList.remove('wraped-right')
    this.homeSelector.classList.remove('width-right')
    this.isOpen = false
  }

}
