import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { environment } from "../../../environments/environment";
import { ToastService } from "../services/toast/toast.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private _return_url: string = environment.RETURN_URL;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastService: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.authenticationService.logout();
          // this.router.navigateByUrl('/authentication/login');
          // window.location.href = this._return_url;
          this.router.navigateByUrl("/login");
        }

        if (err.status === 500) {
          this.toastService.warning("Internal Server Error (500)");
        }

        return throwError(err);
      })
    );
  }
}
