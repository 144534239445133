import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpModule } from "@angular/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

// Fullcalendar imports
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import { AppComponent } from "./app.component";

import { AppRoutingModule } from "./app.routing";

import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AppService } from "./app.service";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SharedModule } from './shared/shared.module';
import { AuthenticationInterceptor } from './core/interceptors/authentication.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { SecurityComponentComponent } from './modules/auth/security-component/security-component.component';

// Fullcalenda plugins registered
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin,
  listPlugin
]);

export function init_app(appService: AppService) {
  return () => appService.load();
}
const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  imports: [
    BrowserModule,
    FontAwesomeModule,
    SharedModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
    BsDatepickerModule.forRoot(),
    NgbModule,
    HttpModule,
    HttpClientModule,
    NgSelectModule,
    NgxSkeletonLoaderModule,
    AppRoutingModule,
    FullCalendarModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
