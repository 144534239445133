import { LocalServiceService } from './../local-service.service';
import { accionesMock, userPerfilesResponse, maestraPerfiles, relacionesMain, categoriasResponse } from './authentication.mocks';
import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, of, Observable, forkJoin } from 'rxjs';
import { map, tap, catchError, switchMap, flatMap } from 'rxjs/operators';
import { SessionResponse } from 'app/modules/authentication/interfaces/session-response.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public keys: any[] = [];
  private _apiUrl: string = environment.API_URL;
  private currentUserObservable: BehaviorSubject<SessionResponse> = new BehaviorSubject({
    id: null,
    nombre: '',
    apellido: '',
    email: '',
    perfil: [],
    puesto: '',
    perfil_descripcion: '',
    ejecutivo_id:0,
    ejecutivo_perfil: null,
    nombre_apellido: '',
    solicitudes_abiertas: null
  });


  constructor(
    private http: HttpClient,
    public authService: AuthService,
    public localService: LocalServiceService
    ) { }

  get keys_(){
    return this.keys
  }
  get currentUser() {
    return this.currentUserObservable.asObservable();
  };

  set currentUserChangeValue(value: SessionResponse) {
    this.currentUserObservable.next(value);
  };


  verify(codigoInput: string): boolean{
    return this.keys.find((access)=> access.codigo === codigoInput)?.access
  }
  

  getSession(token: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<SessionResponse>(`${this._apiUrl}/session/`, { headers })
              .pipe(
                tap(resp => {
                  if (resp && resp.id) {
                    localStorage.setItem('token', token)
                    localStorage.setItem('ejecutivo_id', resp.ejecutivo_id)
                  };
                }),
              );
  };

  isAuthenticated(): Observable<boolean> {
    const token = localStorage.getItem('token') || '';
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<SessionResponse>(`${this._apiUrl}/session/`, { headers })
          .pipe(
            tap((resp: any) => {
              if (resp && resp.id) {
                this.currentUserChangeValue = resp;
                this.localService.setJsonValue('user',resp)
                // Cada vez que se valide la session, tambien se actualizaran los elementos de permisos
                // de cada perfil que tenga el usuario
                // this.getAccessPermisions(token,headers)
              }
            }),
            flatMap((next: any) =>{
              // this.setFakeValuesBD()
              return this.updatePerfilesPermissions(next)
            }),
            map(resp => {
              return true
            }),
            catchError(err => of(false))
          )
  };

  updatePerfilesPermissions(next: any = this.localService.getJsonValue('user')){
    const token = localStorage.getItem('token') || '';
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const { ejecutivo_perfil } = next
    //En este request se deben enviar los perfiles que posee un usuario
    // Por eso es necesario el objeto next
    const urlAccionesPerfiles     = `${this._apiUrl}/perfiles/perfiles/${ejecutivo_perfil}/`;//Agregar perfiles al request
    const urlAcciones             = `${this._apiUrl}/perfiles/acciones/?page_size=1000`;

    //Se usa un pipe de map para simular los request necesarios 
    //para que la logica de permisos se pueda ejecutar con exito
    //Cuando existan endpoints en el backend estos bloques seran comentados
    const PerfilKeysRequest = this.http.get(urlAccionesPerfiles, { headers }).pipe(map((res: any)=>{
      // const main = this.localService.getJsonValue('relacionesMain')
      // res = main.filter((relacion)=> relacion.id_perfil === 1)
      // this.currentUserChangeValue = resp;
      let userActual =this.localService.getJsonValue('user');
      userActual.grupo_seguridad_nombre = res.nombre
      userActual.grupo_seguridad_codigo = res.codigo
      this.currentUserChangeValue = userActual;

      return res['acciones']
    }))
    const accionesRequest         = this.http.get(urlAcciones, { headers }).pipe(map((res)=>{
      // const main = this.localService.getJsonValue('accionesMock')
      // res = main
      return res['results']
    }))

    return forkJoin(
      {
        PerfilKeysRequest: PerfilKeysRequest,
        accionesRequest  : accionesRequest
      }
    ).pipe(
      map((res: any)=>{
          this.setAccessMap(res.accionesRequest,res.PerfilKeysRequest)
        return res
      })
    )
  }

  getKeys(){
    return this.keys
  }


  setAccessMap(acciones: any[],keys: number[]){
    const permisosMap = acciones.map((accion)=>{
      accion.access = keys.some((key) => key === accion.id)
      return accion
    })

    this.keys = permisosMap
    // console.log(this.keys)
    this.localService.setJsonValue('access_map',permisosMap)
    const a = permisosMap.map((i)=>{
      delete i.id
      return i
    })
    let uniq = [...new Set(a)];
    uniq = uniq.map((e,i)=>{
      e.id = i
      return e
    })
  }

  setFakeValuesBD(){
    this.localService.setJsonValue('maestraPerfiles',maestraPerfiles)
    this.localService.setJsonValue('accionesMock',accionesMock)
    this.localService.setJsonValue('relacionesMain',relacionesMain)
    this.localService.setJsonValue('categoriasResponse', categoriasResponse)
  }

  getAccessPermisions(token,headers){
    const urlRequestPerfiles = `${this._apiUrl}/clientes/clientes/${87}/`;
    const perfilesRequest    = this.http.get(urlRequestPerfiles, { headers })
      .pipe(
        map(resp =>{
          let response = [
            {
              "id": 1,
              "nombre": "GERENTE DE SOPORTE",
              "nombre_corto": "Gte. Soporte",
              "descripcion": "Es el gerente encargado de supervisar todas las operaciones de soporte",
              "error": "Gerente no habilitado para esta operacion",
              "codigo": "1G6DV57V280235953"
            }
          ]
          return response
        })
      )


    return perfilesRequest
    
    // .subscribe((res)=>{
    //   console.log(res)
    // })
  }

  getFakeAccess(token,headers){
    const urlRequestPerfiles = `${this._apiUrl}/clientes/clientes/${87}/`;
    const perfilesRequest    = this.http.get(urlRequestPerfiles, { headers })
    // perfilesRequest.subscribe(console.log)
  }

  logout() {
    localStorage.removeItem('token');
  };
}
