import { CafsService } from '../../../core/services/recaudacion-bancaria/cafs.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-temporal',
  templateUrl: './modal-temporal.component.html',
  styleUrls: ['./modal-temporal.component.css']
})
export class ModalTemporalComponent implements OnInit {

  hayURL: any;

  constructor(
    private cafsService: CafsService
  ) { }

  ngOnInit(): void {
    // this.getArchivo();
  }

  // getArchivo() {
  //   this.cafsService.downloadTablaLarga(76)
  //    .then((resp) => {
  //      const binaryData = [];
  //      binaryData.push(resp);

  //      const blob = new Blob(binaryData, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //      const downloadURL = window.URL.createObjectURL(blob);
  //      console.log(downloadURL)
  //      this.hayURL = downloadURL;
  //    })
  //    .catch(err => console.log(err))
  // }
}
