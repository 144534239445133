import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Department, District, Province } from 'app/shared/interfaces/places.interface';
import { PlacesService } from '../../../core/services/cobranza-preventiva/places.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-modal-tests',
  templateUrl: './modal-tests.component.html',
  styles: [
  ]
})
export class ModalTestsComponent implements OnInit {

  myForm: FormGroup;
  departments: Department[] = [];
  provinces: Province[] = [];
  districts: District[] = [];

  departmentSelected: Department;
  provinceSelected: Province;
  districtSelected: District;

  center: google.maps.LatLngLiteral = { lat: -12.0999595, lng: -76.9703576 };
  zoom: number = 15;

  constructor(
    private formBuilder: FormBuilder,
    private placesService: PlacesService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getDepartments();
  }

  initForm() {
    this.myForm = this.formBuilder.group({
      department: ['', Validators.required],
      province: ['', Validators.required],
      district: ['', Validators.required],
      main_address: ['', Validators.required],
      lat: ['', Validators.required],
      lng: ['', Validators.required]
    });

    this.myForm.get('main_address').valueChanges
      .pipe(debounceTime(500))
      .subscribe(value => {
        if (value.trim() === '') return;

        this.geocodeAddress(
          `${value.trim()}, ${this.districtSelected ? this.districtSelected.nombre : ''}, ${this.provinceSelected ? this.provinceSelected.descripcion : ''}`,
          18
        )
      })
  }

  getDepartments() {
    this.placesService.getDepartments()
      .then(resp => {
        this.departments = resp.departamento;
      })
  }

  onChangeDepartmentSelect(event: Department) {
    this.departmentSelected = event;
    this.provinces = [];
    this.districts = [];
    this.myForm.get('province')?.reset('');
    this.myForm.get('district')?.reset('');

    this.placesService.getProvinces(event.id)
      .then(resp => {
        this.provinces = resp.provincia;
      })

    this.geocodeAddress(event.nombre, 12);
  }

  onChangeProvinceSelect(event: Province) {
    this.provinceSelected = event;
    this.districts = [];
    this.myForm.get('district')?.reset('');

    this.placesService.getDistricts(event.id)
      .then(resp => {
        this.districts = resp.distrito;
      });

    this.geocodeAddress(event.descripcion, 12);
  }

  onChangeDistrictSelect(event: District) {
    this.districtSelected = event;

    this.geocodeAddress(event.nombre, 14);
  }

  geocodeAddress(address: string, zoom: number) {
    console.log(address)
    this.placesService.getGeocodeAddress(address)
      .then(({results, status}) => {
        if (status === 'OK') {
          this.zoom = zoom;
          this.center = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }
        }
      })
  }

  onClickMapEvent(event: google.maps.LatLngLiteral) {
    console.log(event);
    this.myForm.get('lat').setValue(event.lat);
    this.myForm.get('lng').setValue(event.lng);
    // this.geocodeLatLng(event);
  }

  // geocodeLatLng(coordinates: google.maps.LatLngLiteral) {
  //   this.placesService.getGeocodeLatLng(coordinates)
  //     .then(({results, status}) => {
  //       console.log(results)
  //       if (status === 'OK') {
  //         if (results[0] && this.myForm.get('main_address').value === '') {
  //           this.myForm.get('main_address').setValue(results[0].formatted_address);
  //         }
  //       }
  //     })
  // }

  onSubmit() {
    if (this.myForm.invalid) {
      this.myForm.markAllAsTouched();
      return;
    };

    console.log(this.myForm.value);
  }

  formControlIsInvalid(controlName: string): boolean {
    return this.myForm.get(controlName).invalid && this.myForm.get(controlName).touched;
  }
}
