import { Injectable } from '@angular/core';
import { ToastService } from '../../core/services/toast/toast.service';

@Injectable({
    providedIn: 'root',
})
export class SharedFunctions {
    public diasSemana: any[] = [
      {
        id: 1,
        descriocion: 'Lunes',
        abrv: 'LUN',
      },
      {
        id: 2,
        descriocion: 'Martes',
        abrv: 'MAR',
      },
      {
        id: 3,
        descriocion: 'Miércoles',
        abrv: 'MIE',
      },
      {
        id: 4,
        descriocion: 'Jueves',
        abrv: 'JUE',
      },
      {
        id: 5,
        descriocion: 'Viernes',
        abrv: 'VIE',
      },
      {
        id: 6,
        descriocion: 'Sábado',
        abrv: 'SAB',
      },
      {
        id: 7,
        descriocion: 'Domingo',
        abrv: 'DOM',
      },
    ];

    public mesesAnio: any[] = [
      {
        id: 1,
        descriocion: 'Enero',
        abrv: 'ENE',
      },
      {
        id: 2,
        descriocion: 'Febrero',
        abrv: 'FEB',
      },
      {
        id: 3,
        descriocion: 'Marzo',
        abrv: 'MAR',
      },
      {
        id: 4,
        descriocion: 'Abril',
        abrv: 'ABR',
      },
      {
        id: 5,
        descriocion: 'Mayo',
        abrv: 'MAY',
      },
      {
        id: 6,
        descriocion: 'Junio',
        abrv: 'JUN',
      },
      {
        id: 7,
        descriocion: 'Julio',
        abrv: 'JUL',
      },
      {
        id: 8,
        descriocion: 'Agosto',
        abrv: 'AGO',
      },
      {
        id: 9,
        descriocion: 'Septiembre',
        abrv: 'SEP',
      },
      {
        id: 10,
        descriocion: 'Octubre',
        abrv: 'OCT',
      },
      {
        id: 11,
        descriocion: 'Noviembre',
        abrv: 'NOV',
      },
      {
        id: 12,
        descriocion: 'Diciembre',
        abrv: 'DIC',
      },
    ];

    yearsForSelect: number[] = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

    constructor(
      public toast: ToastService
    ) {

    }

    validateEmail (email: string): boolean {
        const mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return mailformat.test(email);
    }

    parseDate(date) {
      if (!date) {
        return null;
      }

      const dateNow = new Date();
      const yy = date.split('-')[0];
      const mm = (date.split('-')[1] - 1);
      const dd = (date.split('-')[2]).substring(0, 2);

      const d = new Date(yy, mm, dd, dateNow.getHours());

      return d;
    }

    parseDateReverse(date) {
      if (!date) {
        return null;
      }

      const dateNow = new Date();
      const yy = date.split('/')[2];
      const mm = (date.split('/')[1] - 1);
      const dd = (date.split('/')[0]).substring(0, 2);

      const d = new Date(yy, mm, (dd), dateNow.getHours());

      return d;
    }

    formatDate(date) {
      if (!(date instanceof Date)) { return; }
      return (
        date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      );
    }

    getDateFromString(stringDate) {
      if (!stringDate) { return; }

      const string = this.formaStringDate(stringDate)

      return this.parseDateReverse(string)
    }

    /**
     *
     * @param date 	2021-05-24T15:11:45.148776
     * @returns 24/05/2021
     */
    formaStringDate(date: string): string {
      const fechaArr = date.split('-')
      const anio = fechaArr[0]
      const mes = fechaArr[1]
      const dia = fechaArr[2].split('T')[0]
      return `${dia}/${mes}/${anio}`
    }

    /**
     *
     * @param date 	2021-05-24 00:00
     * @returns 2021-05-24
     */
    cleanDate(date: string): string {
      const fechaDirty = date.split(' ')[0]
      const fechaArr = fechaDirty.split('-')
      const anio = fechaArr[0]
      const mes = (parseInt(fechaArr[1])) < 10 ? `0${fechaArr[1]}` : fechaArr[1]
      const dia = (parseInt(fechaArr[2])) < 10 ? `0${fechaArr[2]}` : fechaArr[2]
      return `${anio}-${mes}-${dia}`
    }

    restaFechas(fechaEntrada: string) {
      const fechaArr: any = fechaEntrada.split('-')

      const anio = parseInt(fechaArr[0])
      const mes = parseInt(fechaArr[1]) - 1
      const dia = parseInt(fechaArr[2])

      const fechaVencimiento = new Date(anio, mes, dia)
      const justoHoy = new Date()
      justoHoy.setHours(0, 0, 0, 0)
      fechaVencimiento.setHours(0, 0, 0, 0)
      const diferenciaDias =  justoHoy.getTime() - fechaVencimiento.getTime()

      const resultadoDias = Math.floor(diferenciaDias / (1000 * 60 * 60 * 24))

      return resultadoDias
    }

    /**
     * IN 2021-10-01
     * Out OCT01
     */
    abrvStringDate(date) {
      const dateObj = this.parseDate(date)

      const mesNumero = dateObj.getMonth() + 1
      const mes = this.mesesAnio.find(mes => mes.id == mesNumero)
      console.log(mes.abrv)

      const textString = `${mes.abrv}${dateObj.getDate()}`
      return textString
    }

    displayErrors(err) {
      if (err && err.error) {
          for (const prop in err.error) {
              this.toast.warning(err.error[prop])
          }
      }
    }

    addDaysToDate(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    restDaysToDate(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
    }

    getFunctionalDate(date: Date) {
      return date.toJSON().slice(0, 10)
    }


    // News functions
    fechaExacta(date: any) {
      const splitFecha = date.split('-');
      splitFecha[2] = (Number(splitFecha[2]) + 1).toString();
      const fechaFinal = splitFecha.join('-');

      return fechaFinal;
    };

    /**
     *
     * @param date 	2021-05-24T15:11:45.148776
     * @returns 2021-05-24
     */
     formaStringDate2(date: string): string {
      const fechaArr = date.split('-');
      const anio = fechaArr[0];
      const mes = fechaArr[1];
      const dia = fechaArr[2].split('T')[0];
      return `${anio}-${mes}-${dia}`;
    };

    /**
     *
     * @param date 	1/29/2022 (M/DD/YYYY)
     * @returns 2022-01-29
     */
    cleanDate2(date: string): string {
      const fechaArr = date.split('/'); // [1, 29, 2022]
      const anio = fechaArr[2];
      const mes = (parseInt(fechaArr[0])) < 10 ? `0${fechaArr[0]}` : fechaArr[0];
      const dia = (parseInt(fechaArr[1])) < 10 ? `0${fechaArr[1]}` : fechaArr[1];
      return `${anio}-${mes}-${dia}`;
    };

    formatDate2(date) {
      if (!(date instanceof Date)) { return; }
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const monthCleaned = month < 10 ? `0${month}` : month;
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

      return year + '-' + monthCleaned + '-' + day;
    }


    objToFmData(obj) {
      const data = new FormData();
      // tslint:disable-next-line: forin
      for (const i in obj) {
        data.append(i, obj[i])
      }

      return data
    }

    /**
     * @param date this param is an instance of Date
     * @returns 2022-09-19T12:10
     */
    toIsoString(date) {
      const pad = function(num: number) {
          const norm = Math.floor(Math.abs(num));
          return (norm < 10) ? `0${norm}` : norm;
      };
    
      return date.getFullYear()
        + '-' + pad(date.getMonth() + 1)
        + '-' + pad(date.getDate())
        + 'T' + pad(date.getHours())
        + ':' + pad(date.getMinutes())
    }

    /**
     * @param date this param is an instance of Date
     * @returns 2022-09-19T12:10:46
     */
    // formatLocaleDate(date: Date) {
    //   const formattedDate = date.toLocaleDateString(); // 9/19/2022
    //   const formattedTime = date.toLocaleTimeString(); // 12:21:10 PM

    //   const dateArray = formattedDate.split('/');
    //   const year = dateArray[2];
    //   const month = (parseInt(dateArray[0])) < 10 ? `0${dateArray[0]}` : dateArray[0];
    //   const day = (parseInt(dateArray[1])) < 10 ? `0${dateArray[1]}` : dateArray[1];

    //   const timeArray = formattedTime.split(' ');
    //   const time = timeArray[0];

    //   return ''
    // }
}
