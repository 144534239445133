import { SecurityComponentComponent } from "./../modules/auth/security-component/security-component.component";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { UiSwitchModule } from "ngx-ui-switch";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { NgxCurrencyModule } from "ngx-currency";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { GoogleMapsModule } from "@angular/google-maps";

import { SessionNotificationComponent } from "./components/session-notification/session-notification.component";
import { ToastComponent } from "./components/toast/toast.component";
import { FactoringDatatableComponent } from "./components/factoring-datatable/factoring-datatable.component";
import { MonedaPipe } from "./pipes/moneda.pipe";
import { DownloadLinkPipe } from "./pipes/download-link.pipe";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { DomSeguroPipe } from "./pipes/dom-seguro.pipe";
import { BuscadorEntidadesComponent } from "./components/buscador-entidades/buscador-entidades.component";
import { ModalTemporalComponent } from "./components/modal-temporal/modal-temporal.component";
import { ButtonBackComponent } from "./components/button-back/button-back.component";
import { ModalTestsComponent } from "./components/modal-tests/modal-tests.component";
import { MapaComponent } from "./components/mapa/mapa.component";
import { MatMenuModule } from "@angular/material/menu";
import { ModalDescuentoInteresComponent } from "./modal-descuento-interes/modal-descuento-interes.component";
import { NoImagePipe } from "./pipes/no-image.pipe";
import { FullCalendarModule } from "@fullcalendar/angular";
import { VistapreviaComponent } from "./vistaprevia/vistaprevia.component";
import { ModalDetalleAbonosComponent } from "./components/modal-detalle-abonos/modal-detalle-abonos.component";

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    BuscadorEntidadesComponent,
    SessionNotificationComponent,
    ToastComponent,
    FactoringDatatableComponent,
    MonedaPipe,
    DownloadLinkPipe,
    ConfirmModalComponent,
    DomSeguroPipe,
    ModalTemporalComponent,
    ButtonBackComponent,
    ModalTestsComponent,
    MapaComponent,
    ModalDescuentoInteresComponent,
    NoImagePipe,
    SecurityComponentComponent,
    VistapreviaComponent,
    ModalDetalleAbonosComponent,
  ],
  imports: [
    MatMenuModule,
    CommonModule,
    NgxSkeletonLoaderModule,
    JwBootstrapSwitchNg2Module,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    //    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    NgxCurrencyModule,
    TooltipModule,
    BsDropdownModule.forRoot(),
    FontAwesomeModule,
    PopoverModule,
    GoogleMapsModule,
  ],
  exports: [
    ModalDetalleAbonosComponent,
    SessionNotificationComponent,
    ToastComponent,
    FactoringDatatableComponent,
    MonedaPipe,
    DownloadLinkPipe,
    DomSeguroPipe,
    ConfirmModalComponent,
    FontAwesomeModule,
    NgbModule,
    BuscadorEntidadesComponent,
    ButtonBackComponent,
    ModalTestsComponent,
    MapaComponent,
    NoImagePipe,
    FullCalendarModule,
    SecurityComponentComponent,
    VistapreviaComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
