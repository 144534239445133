import { AuthenticationService } from './../../../core/services/authentication/authentication.service';
import { StorageService } from './../../../core/services/storage.service';
import { LocalServiceService } from './../../../core/services/local-service.service';
import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-security-component',
  templateUrl: './security-component.component.html',
  styleUrls: ['./security-component.component.css']
})
export class SecurityComponentComponent implements OnInit {
  @ContentChild('displayContent') displayContent: TemplateRef<any>; 
  @Input() data: any;
  @Input() codigoAccion: string;
  toggleDisplay: boolean = false;
  accessMap: any;

  constructor(
    public localService: LocalServiceService,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.toggleDisplay = this.verify()
  }

  verify(codigoInput: string = this.codigoAccion): boolean{
    this.accessMap = this.localService.getJsonValue('access_map')
    return this.accessMap.find((access)=> access.codigo === codigoInput)?.access
  }

  static allow(authService: AuthenticationService, code: string = ''): boolean{
    return authService.keys_.find((access)=> access.codigo === code)?.access
  }

}
