import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  @Input() title = '';
  @Input() message = '';
  @Input() messageHTML : boolean = false;
  @Input() messageTemplate : boolean = false;
  @Input() templateRef: TemplateRef<any> = null;

  @Input() titleLeftBottom = 'Confirmar';
  @Input() titleRightBottom = 'Cancelar';
  @Input() showBottomLeft = true;
  @Input() showBottomRight = true;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

}
