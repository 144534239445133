import { CafsService } from 'app/core/services/recaudacion-bancaria/cafs.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ClientesService } from 'app/core/services/clientes/clientes.service'
import { concat, Observable, of, Subject, throwError } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map, filter } from 'rxjs/operators'

@Component({
  selector: 'app-buscador-entidades',
  templateUrl: './buscador-entidades.component.html',
  styleUrls: ['./buscador-entidades.component.css']
})
export class BuscadorEntidadesComponent implements OnInit {
  
  // Las entidades pueden ser: 
  // - cliente
  // - aceptante

  entidadesDisponibles : string[] = ['cliente','aceptante','placa'];
  _entidad : string
  @Input() set entidad(val){
    if(val && this.entidadesDisponibles.includes(val)){
      this._entidad = val
    }
  }
  get entidad(){
    return this._entidad
  }

  public _disabled = false;;
  @Input() set disabled(val){
    if(val){
      setTimeout(() => {
        this.contenedorBuscador.disable()
      }, 100);
    } else {
      setTimeout(() => {
        this.contenedorBuscador.enable()
      }, 100);
    }
  }
  get disabled(){
    return this._entidad
  }


  // Titulo del label del input
  @Input() titulo    : string = 'Entidad';
  @Input() precargado: [] = [];
  public _identidad  : any = '';

  @Input() set identidad(val){
    if(!!val){
      setTimeout(()=> {
        this.contenedorBuscador.get('campoBusqueda').setValue(val)
      }, 100)
      this._identidad = val
    }
  }
  get identidad(){
    return this._identidad
  }

	entidadEndpoint$ : Observable<any>
	obsevableEntidad$: Observable<any[]>
	textoDeBusqueda$ = new Subject<string>()
	caracteresMinimos: number  = 4
  historialDeBusqueda: any[] = [];
	ruc              : any
	loadingSearch    : boolean = false
	public contenedorBuscador : FormGroup
  @Output() seleccionDeEntidad = new EventEmitter<boolean>();
  @Output() resetSelection = new EventEmitter<boolean>();

  constructor(
		public formbuild     : FormBuilder,
    public clientesService: ClientesService,
    public cafService: CafsService,
  ) { }

  ngOnInit(): void {

    this.contenedorBuscador = this.formbuild.group({
			campoBusqueda        : [null, [Validators.required]],
		})

    this.contenedorBuscador.get('campoBusqueda').valueChanges.subscribe((valueChanged)=>{
      let resultadoSeleccion = this.historialDeBusqueda.find( elem => elem.id === valueChanged)
      this.seleccionDeEntidad.emit(resultadoSeleccion)
    })

    this.cargarBuscador()
  }

  cargarBuscador(){
		/**
		 * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
		 * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
		 * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value. 
		 * 		So that API will not hit if the current and previous value is the same
		 * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
		 * 		string typed by user
		 */

		this.obsevableEntidad$ = concat(
			of( this.precargado ), // Items predeterminados
			this.textoDeBusqueda$.pipe(
			filter(res => {
				return res !== null && res.length >= this.caracteresMinimos
			}),
			distinctUntilChanged(),
			debounceTime(1000),
			tap(() => this.loadingSearch = true),
			switchMap(textoBusqueda => {
				let request : Observable<any> = this.entidadEndpoint(textoBusqueda)
				return request.pipe(
					catchError(() => of([])), // empty list on error
					tap((resultados) => {
            this.historialDeBusqueda = resultados
            this.loadingSearch = false
          })
					)
			})
			)
		)

	}

  /**
   * Contiene los casos de las distintas entidades
   * de busqueda
   * @param textoBusqueda Texto de entrada del campo select de busqueda
   * @returns retorna una promesa observable que retorna resultados
   */
  entidadEndpoint(textoBusqueda) : Observable<any> {

    let request : Observable<any>

    switch(this.entidad){
      case 'cliente':
        request = this.clientesService.obtenerClientesObservable(1, 1000, '', textoBusqueda)
        return request
      case 'placa':
        request = this.cafService.getContratosByPlaca(1,1000,textoBusqueda)
      return request
      default:
        console.error('La entidad que intenta usar no existe o no esta configurada')
        return null
    }

  }

  resetForm(){
    this.contenedorBuscador.get('campoBusqueda').setValue(null)
    this.resetSelection.emit(null)
  }
}
