import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  ContratosCAFResponse,
  ContratoCAF,
} from "../../../shared/interfaces/contratosCAF-response.interface";
import { CAFCuotasResponse } from "../../../shared/interfaces/cafCuotas-response.interface";
import { SpinnerService } from "../spinner.service";
import { ConceptoCuotaResponse } from "../../../shared/interfaces/concepto-cuota.interface";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { EstadosCAFResponse } from "app/shared/interfaces/estados-caf-response.interface";
import { SharedFunctions } from "../../../shared/helpers/functions";
import { addFiltersHelper } from "./../../../shared/helpers/addReportFilters";
@Injectable({
  providedIn: "root",
})
export class CafsService {
  private _apiUrl: string = environment.API_URL;

  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private _sharedFunctions: SharedFunctions
  ) {}

  getContratosCAF(
    page: number = 1,
    page_size: number = 10,
    codigo__icontains: string = "",
    cliente__nombre__icontains: string = "",
    tipo_moneda: string = "",
    valor_a_financiar__gte: string = "",
    valor_a_financiar__lte: string = "",
    fecha_inicio_contrato__gte: string = "",
    fecha_inicio_contrato__lte: string = "",
    fecha_finalizacion__gte: string = "",
    fecha_finalizacion__lte: string = "",
    incluye_seguro: string = "",
    estado_contrato: string = "",
    cerrados: string = "0"
  ) {
    const url = `${this._apiUrl}/contratos/contratos/`;
    const params = new HttpParams()
      .set("page", page.toString())
      .set("page_size", page_size.toString())
      .set("codigo__icontains", codigo__icontains)
      .set("cliente__nombre__icontains", cliente__nombre__icontains)
      .set("tipo_moneda", tipo_moneda)
      .set("valor_a_financiar__gte", valor_a_financiar__gte)
      .set("valor_a_financiar__lte", valor_a_financiar__lte)
      .set("fecha_inicio_contrato__gte", fecha_inicio_contrato__gte)
      .set("fecha_inicio_contrato__lte", fecha_inicio_contrato__lte)
      .set("fecha_finalizacion__gte", fecha_finalizacion__gte)
      .set("fecha_finalizacion__lte", fecha_finalizacion__lte)
      .set("incluye_seguro", incluye_seguro)
      .set("estado_contrato", estado_contrato)
      .set("cerrados", cerrados);

    return new Promise<ContratosCAFResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<ContratosCAFResponse>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getContratoCAF(contratoID: string) {
    const url = `${this._apiUrl}/contratos/contratos/${contratoID}/`;

    return new Promise<ContratoCAF>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<ContratoCAF>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getBucketFile(urlPath: string) {
    const url = `${this._apiUrl}/descargar/?recurso=${urlPath}`;
    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<any>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getContratosByCliente(clienteID: string) {
    const url = `${this._apiUrl}/contratos/contratos/?cliente=${clienteID}&page=1&page_size=1000`;
    return new Promise<ContratosCAFResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<ContratosCAFResponse>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getContratosByPlaca(
    page = 1,
    page_size = 10,
    placaString: string
  ): Observable<any> {
    const url = `${this._apiUrl}/contratos/contratos/?placa__icontains=${placaString}&page=${page}&page_size=${page_size}`;

    return this.http.get<any>(url).pipe(
      map((resp) => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.results;
          // return [];
        }
      })
    );
  }

  getContVehiculoByCliente(clienteID: string) {
    const url = `${this._apiUrl}/contratos/contratos/?cliente=${clienteID}&page=1&page_size=1000&tipo_bien__icontains=VEHÍCULOS`;

    return new Promise<ContratoCAF>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<ContratoCAF>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  // getEstadoXLS(clienteID: string) {
  //   const url = `${this._apiUrl}/contratos/contratos/descargar_estado_cuenta_xlsx/?contrato=${clienteID}`;
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json');

  //   return new Promise<ContratoCAF>((resolve, reject) => {
  //     this.spinnerService.spinnerOn();
  //     this.http.get<ContratoCAF>(url,{headers, responseType: 'blob' as 'json'}).subscribe(
  //       (resp) => {
  //         this.spinnerService.spinnerOff();
  //         resolve(resp);
  //       },
  //       (err) => {
  //         this.spinnerService.spinnerOff('Algo salió mal', false);
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  getEstadoCuentaXLS(contratoID: number | string, descuento: number = 0) {
    const url = `${this._apiUrl}/contratos/contratos/descargar_estado_cuenta_xlsx/`;
    const params = new HttpParams()
      .set("contrato", contratoID)
      .set("descuento", descuento);

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url, {
          params,
          observe: "response",
          responseType: "blob" as "json",
        })
        .subscribe(
          (data) => {
            this.spinnerService.spinnerOff();
            resolve(data);
          },
          async (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  getEstadoCuentaPDF(contratoID: number | string, descuento: number = 0) {
    const url = `${this._apiUrl}/contratos/contratos/descargar_estado_cuenta_pdf/`;
    const params = new HttpParams()
      .set("contrato", contratoID)
      .set("descuento", descuento);

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url, {
          params,
          observe: "response",
          responseType: "blob" as "json",
        })
        .subscribe(
          (data) => {
            this.spinnerService.spinnerOff();
            resolve(data);
          },
          async (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  getDescuentos(page: number = 1, page_size: number = 10, contrato: any = "") {
    const url = `${this._apiUrl}/entidades/descuento/`;

    const params = new HttpParams()
      .set("page", page.toString())
      .set("page_size", page_size.toString())
      .set("contrato", contrato);

    return new Promise((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err: HttpErrorResponse) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getCAFCuotas(
    cafID: number,
    page: number = 1,
    page_size: number = 10,
    nro_cuota__icontains: string = "",
    fecha_cuota__gte: string = "",
    fecha_cuota__lte: string = "",
    fecha_pagado__gte: string = "",
    fecha_pagado__lte: string = "",
    estado_cuota: string = ""
  ) {
    const url = `${this._apiUrl}/contratos/cuotas/`;
    const params = new HttpParams()
      .set("contrato", cafID.toString())
      .set("page", page.toString())
      .set("page_size", page_size.toString())
      .set("nro_cuota__icontains", nro_cuota__icontains)
      .set("fecha_cuota__gte", fecha_cuota__gte)
      .set("fecha_cuota__lte", fecha_cuota__lte)
      .set("fecha_pagado__gte", fecha_pagado__gte)
      .set("fecha_pagado__lte", fecha_pagado__lte)
      .set("estado_cuota", estado_cuota);

    return new Promise<CAFCuotasResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<CAFCuotasResponse>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getCAFCuotaDetalle(conceptoID: number) {
    const url = `${this._apiUrl}/contratos/cuotas/${conceptoID}/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<any>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  enviarAvisoManualCobranza(
    contratoID: number,
    destinatarios: string[],
    incluir_abonos: boolean
  ) {
    const url = `${this._apiUrl}/acciones/email/manual/`;
    const body = { contrato: contratoID, destinatarios, incluir_abonos };

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.post(url, body).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff("Se envío aviso con éxito");
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  enviarCOPCVehiculo(contratoID: number) {
    const url = `${this._apiUrl}/contratos/contratos/${contratoID}/enviar_carta_opcion_compra/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff(
            "Se envío Carta de Opción de Compra con éxito"
          );
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  enviarEstadoCuenta(contratoID: number) {
    const url = `${this._apiUrl}/contratos/contratos/${contratoID}/enviar_estado_de_cuenta/`;

    return new Promise((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff("Se envió estado de cuenta con éxito");
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  downloadTablaLarga(contratoID: number) {
    const url = `${this._apiUrl}/contratos/contratos/${contratoID}/descargar_tabla_larga_caf/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url, { observe: "response", responseType: "blob" })
        .subscribe(
          (data) => {
            this.spinnerService.spinnerOff();
            resolve(data);
          },
          async (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  crearConceptoCuota(data: any) {
    const url = `${this._apiUrl}/contratos/crear-concepto-cuota/`;

    return new Promise<ConceptoCuotaResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.post<ConceptoCuotaResponse>(url, data).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff(
            "Se agregó concepto a contrato con éxito"
          );
          resolve(resp);
        },
        (err) => {
          if (err.error && err.error.error) {
            this.spinnerService.spinnerOff(err.error.error, false);
          } else {
            this.spinnerService.spinnerOff("Algo salió mal", false);
          }
          reject(err);
        }
      );
    });
  }

  putConcepto(conceptoID: number, data: any) {
    const url = `${this._apiUrl}/contratos/editar-concepto-cuota/${conceptoID}/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.put<any>(url, data).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff("Se actualizó concepto con éxito");
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  deleteConcepto(conceptoID: number) {
    const url = `${this._apiUrl}/contratos/cuotas/${conceptoID}/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.delete<any>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff("Se eliminó concepto con éxito");
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  verificarConceptoRepetido(
    conceptoID: number,
    contratoID: number,
    fecha: string
  ) {
    const url = `${this._apiUrl}/conceptos/conceptos/${conceptoID}/validar_repeticion_concepto/`;
    const params = new HttpParams()
      .set("contrato", contratoID.toString())
      .set("fecha", fecha);

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  downloadLiquidacion(contratoID: number, descuento: number) {
    if (descuento === null) descuento = 0;
    console.log(descuento);
    const url = `${this._apiUrl}/contratos/contratos/${contratoID}/descargar_liquidacion_pdf/?descuento=${descuento}`;
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get(url, { headers, responseType: "blob" as "json" }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  enviarAvisoUltimaCuota(
    contratoID: number,
    destinatarios: string[],
    descuento: number = 0
  ) {
    const url = `${this._apiUrl}/contratos/contratos/${contratoID}/enviar_email_aviso_cierre/`;
    const body = { destinatarios, descuento };

    return new Promise((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.post(url, body).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff("Aviso ha sido enviado con éxito");
          resolve(resp);
        },
        (err) => {
          if (err.error && err.error.mensaje) {
            this.spinnerService.spinnerOff(err.error.mensaje, false);
          } else {
            this.spinnerService.spinnerOff("Algo salió mal", false);
          }
          reject(err);
        }
      );
    });
  }

  getContratosPendientesByCliente(clienteID: string) {
    const url = `${this._apiUrl}/clientes/clientes/${clienteID}/contratos_cuotas_vencidas/`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<any>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getEstadosCAF(filtro: string = "") {
    const url = `${this._apiUrl}/contratos/estado_contrato/`;
    const params = new HttpParams().set("filtro", filtro);

    return new Promise<EstadosCAFResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<EstadosCAFResponse>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  updateEstadoCAF(contratoID: number, estado_contrato: number) {
    const url = `${this._apiUrl}/contratos/contratos/${contratoID}/`;
    const body = { estado_contrato };

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.patch<any>(url, body).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff(
            "Estado de CAF ha sido cambiado con éxito"
          );
          resolve(resp);
        },
        (err) => {
          if (err.error && err.error.mensaje) {
            this.spinnerService.spinnerOff(err.error.mensaje, false);
          } else {
            this.spinnerService.spinnerOff("Algo salió mal", false);
          }
          reject(err);
        }
      );
    });
  }

  downloadReport(filters: any) {
    const url = `${
      this._apiUrl
    }/contratos/contratos/descargar_reporte_xlsx/?${addFiltersHelper(filters)}`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url, { observe: "response", responseType: "blob" })
        .subscribe(
          (resp) => {
            this.spinnerService.spinnerOff();
            resolve(resp);
          },
          (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  getCuotasForDiscountCampaigns(
    conceptoID: number,
    page: number = 1,
    page_size: number = 10,
    contrato__cliente__grupo: string = "",
    contrato__estado_contrato_id: string = ""
  ) {
    const url = `${this._apiUrl}/contratos/cuotas/`;
    const params = new HttpParams()
      .set("estado_cuota__in", "1,2") // parámetro fijo
      .set("fecha_cuota__lte", this._sharedFunctions.formatDate(new Date())) // parámetro fijo
      .set("tipo_concepto", conceptoID) // parámetro fijo
      .set("page", page)
      .set("page_size", page_size)
      .set("contrato__cliente__grupo", contrato__cliente__grupo)
      .set("contrato__estado_contrato_id", contrato__estado_contrato_id);

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<any>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  validateConcepts(cuotas: number[]) {
    const url = `${this._apiUrl}/contratos/cuotas/validar_conceptos_sensibles/`;
    const data = { cuotas };

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.post<any>(url, data).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  downloadCuotasReport(contratoID: number, filters: any) {
    const url = `${
      this._apiUrl
    }/contratos/contratos/${contratoID}/descargar_detalle_cuotas_xlsx/?${addFiltersHelper(
      filters
    )}`;

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url, { observe: "response", responseType: "blob" })
        .subscribe(
          (resp) => {
            this.spinnerService.spinnerOff();
            resolve(resp);
          },
          (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  enviarEstadoCuentaConDescuento(
    contratoID: number,
    destinatarios: string[],
    descuento: number = 0
  ) {
    const url = `${this._apiUrl}/contratos/contratos/${contratoID}/enviar_estado_de_cuenta/`;
    const body = { destinatarios, descuento };

    return new Promise((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.post(url, body).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff("Aviso ha sido enviado con éxito");
          resolve(resp);
        },
        (err) => {
          if (err.error && err.error.mensaje) {
            this.spinnerService.spinnerOff(err.error.mensaje, false);
          } else {
            this.spinnerService.spinnerOff("Algo salió mal", false);
          }
          reject(err);
        }
      );
    });
  }
}
