import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Departments, Districts, Provinces } from 'app/shared/interfaces/places.interface';
import { SpinnerService } from '../spinner.service';
import { MapGeocoder, MapGeocoderResponse } from '@angular/google-maps';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  private _apiUrl: string = 'https://sglt-dev-back.totalsf.com.pe/api/v1.0';

  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private geocoder: MapGeocoder
  ) { }

  getDepartments() {
    const url = `${this._apiUrl}/departamento`;

    return new Promise<Departments>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<Departments>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff('Algo salió mal', false);
          reject(err);
        }
      )
    });
  }

  getProvinces(departmentID: number) {
    const url = `${this._apiUrl}/provincia/${departmentID}`;

    return new Promise<Provinces>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<Provinces>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff('Algo salió mal', false);
          reject(err);
        }
      )
    });
  }

  getDistricts(provinceID: number) {
    const url = `${this._apiUrl}/distrito/${provinceID}`;

    return new Promise<Districts>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<Districts>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff('Algo salió mal', false);
          reject(err);
        }
      )
    });
  }

  getGeocodeAddress(address: string) {
    return new Promise<MapGeocoderResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.geocoder.geocode({ address }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff('Algo salió mal', false);
          reject(err);
        }
      )
    })
  }

  getGeocodeLatLng(coordinates: google.maps.LatLngLiteral) {
    return new Promise<MapGeocoderResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.geocoder.geocode({ location: coordinates }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff('Algo salió mal', false);
          reject(err);
        }
      )
    })
  }
}
