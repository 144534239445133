import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthenticationGuard } from './core/guards/authentication.guard';

export const routes: Routes = [
    {
        path: 'authentication',
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: 'cobranza',
        loadChildren: () => import('./layouts/admin/admin-layout.module').then(m => m.AdminLayoutModule),
        canActivate: [AuthenticationGuard],
        canLoad: [AuthenticationGuard]
    },
    {
        path: '**',
        redirectTo: 'authentication'
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy'
})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}