import { Usuario } from "./Usuario";
export class Perfil {
  public perfilAdmin                  : number = 1;
  public perfilClienteID              : number = 2;
  public perfilOficialDeNegocioID     : number = 3;
  public perfilGerenciaComercialID    : number = 4;
  public perfilGerenciaGeneralID      : number = 16;
  public perfilJefeDeAdmisionID       : number = 5;
  public perfilAnalistaRiesgosID      : number = 6;
  public perfilLegalID                : number = 7;
  public perfilAsesorLegalID          : number = 8;
  public perfilJefeDeOperacionesID    : number = 9;
  public perfilSubGerenciaComercialID : number = 10;
  public perfilTesoreriaID            : number = 11;
  public perfilLiderDeTesoreriaID     : number = 17;
  public perfilRecepcionID            : number = 12;
  public perfilAsistenteCobranzasID   : number = 13;
  public perfilAnalistaOperacionesID  : number = 14;
  public perfilLiderDeEquipo           : number = 18;
  public perfilConsultaID: number = 15;
  public user                         : Usuario;

  constructor(user: Usuario = null) {
    if(user){
      this.user = user;
    }
  }

  get isAdmin() {
    if (this.user.perfil === this.perfilAdmin) return true;
    else return false;
  }

  get isCliente() {
    if (this.user.perfil === this.perfilClienteID) return true;
    else return false;
  }

  get isOficialDeNegocio() {
    if (this.user.perfil === this.perfilOficialDeNegocioID) return true;
    else return false;
  }

  get isAnalistaRiesgo() {
    if (this.user.perfil === this.perfilAnalistaRiesgosID) return true;
    else return false;
  }

  get isGerenteComercial() {
    if (this.user.perfil === this.perfilGerenciaComercialID) return true;
    else return false;
  }

  get isGerenteGeneral() {
    if (this.user.perfil === this.perfilGerenciaGeneralID) return true;
    else return false;
  }

  get isJefeDeAdmision() {
    if (this.user.perfil === this.perfilJefeDeAdmisionID || this.isAnalistaRiesgo) return true;
    else return false;
  }

  get isAsesorLegal() {
    if (this.user.perfil === this.perfilAsesorLegalID) return true;
    else return false;
  }

  get isLegal() {
    if (this.user.perfil === this.perfilLegalID) return true;
    else return false;
  }

  get isJefeDeOperaciones() {
    if (this.user.perfil === this.perfilJefeDeOperacionesID) return true;
    else return false;
  }

  get isSubGerentecomercial() {
    if (this.user.perfil === this.perfilSubGerenciaComercialID) return true;
    else return false;
  }

  get isTesoreria() {
    if (this.user.perfil === this.perfilTesoreriaID) return true;
    else return false;
  }

  get isRecepcion() {
    if (this.user.perfil === this.perfilRecepcionID) return true;
    else return false;
  }

  get isAsistenteCobranzas() {
    if (this.user.perfil === this.perfilAsistenteCobranzasID) return true;
    else return false;
  }

  // get isSupervisorDeCobranzas() {
  //   if (this.user.perfil === this.perfilSupervisorDeCobranzasID) return true;
  //   else return false;
  // }

  get isAnalistaDeOperaciones() {
    if (this.user.perfil === this.perfilAnalistaOperacionesID) return true;
    else return false;
  }

  get isLiderDeTesoreria() {
    if (this.user.perfil === this.perfilLiderDeTesoreriaID) return true;
    else return false;
  }

  get isLiderDeEquipo() {
    if (this.user.perfil === this.perfilLiderDeEquipo) return true;
    else return false;
  }

  public isResponsableDeOperacion(operacion) {
    if (operacion.responsable === this.user.id) {
      return true;
    } else {
      return false;
    }
  }
}
