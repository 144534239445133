import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";

import { environment } from "environments/environment";
import {
  Abono,
  AbonosResponse,
} from "../../../shared/interfaces/abonos-response.interface";
import { PlanillaResponse } from "../../../shared/interfaces/planilla-response.interface";
import { PlanillasResponse } from "app/shared/interfaces/planillas-response.interface";
import { SpinnerService } from "../spinner.service";
import { EstadosAbonosResponse } from "../../../shared/interfaces/estados-abonos-response.interface";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PlanillasService {
  private _apiUrl: string = environment.API_URL;

  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService
  ) {}

  getPlanillas(
    page: number = 1,
    page_size: number = 10,
    fecha__gte: any = "",
    fecha__lte: any = "",
    cuenta_bancaria__banco__nombre__icontains: string = "",
    cuenta_bancaria__numero__icontains: string = "",
    cuenta_bancaria__tipo_moneda: string = "",
    monto__range: string = "",
    estado_planilla: string = ""
  ) {
    const url = `${this._apiUrl}/planillas/planillas/`;
    const params = new HttpParams()
      .set("page", page.toString())
      .set("page_size", page_size.toString())
      .set("fecha__gte", fecha__gte)
      .set("fecha__lte", fecha__lte)
      .set(
        "cuenta_bancaria__banco__nombre__icontains",
        cuenta_bancaria__banco__nombre__icontains
      )
      .set(
        "cuenta_bancaria__numero__icontains",
        cuenta_bancaria__numero__icontains
      )
      .set("cuenta_bancaria__tipo_moneda", cuenta_bancaria__tipo_moneda)
      .set("monto__range", monto__range)
      .set("estado_planilla", estado_planilla);

    return new Promise<PlanillasResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<PlanillasResponse>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err: HttpErrorResponse) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getPlanilla(planillaID: string) {
    const url = `${this._apiUrl}/planillas/planillas/${planillaID}/`;

    return new Promise<PlanillaResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<PlanillaResponse>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err: HttpErrorResponse) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getAbonosEstados({ id }: Abono) {
    const url = `${this._apiUrl}/planillas/habilitacion/`;
    const params = new HttpParams().set("abono", id);
    return new Promise<AbonosResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<AbonosResponse>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getAbonosInfo({ id }: Abono) {
    const url = `${this._apiUrl}/planillas/identificacion-abono/`;
    const params = new HttpParams().set("abono", id);
    return new Promise<AbonosResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<AbonosResponse>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getAbonosExtorno({ id, estado__in, ordering }: Abono) {
    const url = `${this._apiUrl}/contratos/aplicaciones/`;
    const params = new HttpParams()
      .set("abono", id)
      .set("estado__in", estado__in)
      .set("ordering", ordering);

    return new Promise<AbonosResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<AbonosResponse>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getAbonos(
    planillaID: string,
    estado_abono__habilitado: boolean,
    cliente__isnull?: boolean,
    page: number = 1,
    page_size: number = 10,
    referencia__icontains: string = "",
    importe__range: string = "",
    numero_operacion__icontains: string = "",
    cliente__nombre__icontains: string = "",
    estado_abono: string = ""
  ) {
    const url = `${this._apiUrl}/planillas/planilla/${planillaID}/`;
    const params = new HttpParams()
      .set("estado_abono__habilitado", estado_abono__habilitado)
      .set("page", page.toString())
      .set("page_size", page_size.toString())
      .set("referencia__icontains", referencia__icontains)
      .set("importe__range", importe__range)
      .set("numero_operacion__icontains", numero_operacion__icontains)
      .set("cliente__nombre__icontains", cliente__nombre__icontains)
      .set("estado_abono", estado_abono)
      .set("cliente__isnull", cliente__isnull);

    return new Promise<AbonosResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<AbonosResponse>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err: HttpErrorResponse) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getAbono(abonoID: number) {
    const url = `${this._apiUrl}/planillas/abonos/${abonoID}/`;

    return new Promise<Abono>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<Abono>(url).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err: HttpErrorResponse) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  inhabilitarOHabilitarAbono(abonoID: number, data) {
    const url = `${this._apiUrl}/planillas/abonos/${abonoID}/`;
    const formData = new FormData();
    formData.append("estado_abono", data.estado_abono.toString());
    formData.append("fecha_habilitacion", data.fecha);

    return new Promise<Abono>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.patch<Abono>(url, formData).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff("Abono cambió de estado con éxito");
          resolve(resp);
        },
        (err: HttpErrorResponse) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  cargaManualAbono(data: any) {
    const url = `${this._apiUrl}/planillas/abono-manual/`;

    return new Promise((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.post(url, data).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff(
            "Planilla ha sido registrada exitosamente"
          );
          resolve(resp);
        },
        (err: HttpErrorResponse) => {
          reject(err);
        }
      );
    });
  }

  getAbonosPorAplicar(
    page: number = 1,
    page_size: number = 10,
    fecha__gte: string = "",
    fecha__lte: string = "",
    planilla__cuenta_bancaria__banco__nombre__icontains: string = "",
    planilla__cuenta_bancaria__numero__icontains: string = "",
    planilla__cuenta_bancaria__tipo_moneda: string = "",
    referencia__icontains: string = "",
    importe__range: string = "",
    numero_operacion__icontains: string = "",
    cliente__nombre__icontains: string = "",
    estado_abono: string = "",
    cliente__isnull: boolean = false
  ) {
    const url = `${this._apiUrl}/planillas/abonos/`;
    const params = new HttpParams()
      .set("page", page.toString())
      .set("page_size", page_size.toString())
      .set("fecha__gte", fecha__gte)
      .set("fecha__lte", fecha__lte)
      .set(
        "planilla__cuenta_bancaria__banco__nombre__icontains",
        planilla__cuenta_bancaria__banco__nombre__icontains
      )
      .set(
        "planilla__cuenta_bancaria__numero__icontains",
        planilla__cuenta_bancaria__numero__icontains
      )
      .set(
        "planilla__cuenta_bancaria__tipo_moneda",
        planilla__cuenta_bancaria__tipo_moneda
      )
      .set("referencia__icontains", referencia__icontains)
      .set("importe__range", importe__range)
      .set("numero_operacion__icontains", numero_operacion__icontains)
      .set("cliente__nombre__icontains", cliente__nombre__icontains)
      .set("estado_abono", estado_abono)
      .set("por_aplicar", "1")
      .set("estado_abono__habilitado", true)
      .set("cliente__isnull", cliente__isnull);

    return new Promise<AbonosResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<AbonosResponse>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getEstadosAbonos(habilitado: string = "") {
    const url = `${this._apiUrl}/planillas/estado_abonos/`;
    const params = new HttpParams().set("habilitado", habilitado);

    return new Promise<EstadosAbonosResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get<EstadosAbonosResponse>(url, { params })
        .pipe(
          map((resp) => {
            resp.results.map((estado) => {
              if (estado.habilitado) {
                estado.grupo = "Habilitado";
              } else {
                estado.grupo = "Inhabilitado";
              }
              return estado;
            });
            return resp;
          })
        )
        .subscribe(
          (resp) => {
            this.spinnerService.spinnerOff();
            resolve(resp);
          },
          (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  getPlanillasXLS({
    fecha__gte: fecha__gte = "",
    fecha__lte: fecha__lte = "",
    planilla__cuenta_bancaria__tipo_moneda:
      planilla__cuenta_bancaria__tipo_moneda = "",
    planilla__cuenta_bancaria__banco__nombre__icontains:
      planilla__cuenta_bancaria__banco__nombre__icontains = "",
    planilla__cuenta_bancaria__numero__icontains:
      planilla__cuenta_bancaria__numero__icontains = "",
    referencia__icontains: referencia__icontains = "",
    importe__range: importe__range = "",
    numero_operacion__icontains: numero_operacion__icontains = "",
    cliente__nombre__icontains: cliente__nombre__icontains = "",
    estado_abono: estado_abono = "",
    estado_abono__habilitado = false,
  }) {
    let url = `${this._apiUrl}/planillas/abonos/inhabilitados_factoring`;
    const params = new HttpParams()
      .set("fecha__gte", fecha__gte)
      .set("fecha__lte", fecha__lte)
      .set(
        "planilla__cuenta_bancaria__tipo_moneda",
        planilla__cuenta_bancaria__tipo_moneda
      )
      .set(
        "planilla__cuenta_bancaria__banco__nombre__icontains",
        planilla__cuenta_bancaria__banco__nombre__icontains
      )
      .set("estado_abono__habilitado", estado_abono__habilitado)
      .set(
        "planilla__cuenta_bancaria__numero__icontains",
        planilla__cuenta_bancaria__numero__icontains
      )
      .set("referencia__icontains", referencia__icontains)
      .set("importe__range", importe__range)
      .set("numero_operacion__icontains", numero_operacion__icontains)
      .set("cliente__nombre__icontains", cliente__nombre__icontains)
      .set("estado_abono", estado_abono);

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http
        .get(url, {
          params,
          observe: "response",
          responseType: "blob" as "json",
        })
        .subscribe(
          (data) => {
            this.spinnerService.spinnerOff();
            resolve(data);
          },
          async (err) => {
            this.spinnerService.spinnerOff("Algo salió mal", false);
            reject(err);
          }
        );
    });
  }

  getAbonosInhabilitados(
    page: number = 1,
    page_size: number = 10,
    fecha__gte: string = "",
    fecha__lte: string = "",
    planilla__cuenta_bancaria__banco__nombre__icontains: string = "",
    planilla__cuenta_bancaria__numero__icontains: string = "",
    planilla__cuenta_bancaria__tipo_moneda: string = "",
    referencia__icontains: string = "",
    importe__range: string = "",
    numero_operacion__icontains: string = "",
    cliente__nombre__icontains: string = "",
    estado_abono: string = ""
  ) {
    const url = `${this._apiUrl}/planillas/abonos/`;
    const params = new HttpParams()
      .set("page", page.toString())
      .set("page_size", page_size.toString())
      .set("fecha__gte", fecha__gte)
      .set("fecha__lte", fecha__lte)
      .set(
        "planilla__cuenta_bancaria__banco__nombre__icontains",
        planilla__cuenta_bancaria__banco__nombre__icontains
      )
      .set(
        "planilla__cuenta_bancaria__numero__icontains",
        planilla__cuenta_bancaria__numero__icontains
      )
      .set(
        "planilla__cuenta_bancaria__tipo_moneda",
        planilla__cuenta_bancaria__tipo_moneda
      )
      .set("referencia__icontains", referencia__icontains)
      .set("importe__range", importe__range)
      .set("numero_operacion__icontains", numero_operacion__icontains)
      .set("cliente__nombre__icontains", cliente__nombre__icontains)
      .set("estado_abono", estado_abono)
      .set("estado_abono__habilitado", false);

    return new Promise<AbonosResponse>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<AbonosResponse>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }

  getClienteAbonos(
    clienteID: number,
    page: number = 1,
    page_size: number = 10
  ) {
    const url = `${this._apiUrl}/planillas/abonos/?cliente=${clienteID}`;
    const params = new HttpParams()
      .set("page", page)
      .set("page_size", page_size);

    return new Promise<any>((resolve, reject) => {
      this.spinnerService.spinnerOn();
      this.http.get<any>(url, { params }).subscribe(
        (resp) => {
          this.spinnerService.spinnerOff();
          resolve(resp);
        },
        (err) => {
          this.spinnerService.spinnerOff("Algo salió mal", false);
          reject(err);
        }
      );
    });
  }
}
