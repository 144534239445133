import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from "./toast/toast.service";
import { AppService } from "../../app.service";
import { SweetalertService } from "./sweetalert.service";

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  constructor(
    private _spinner: NgxSpinnerService,
    private _toastService: ToastService,
    private _appService: AppService,
    private _sweetAlertService: SweetalertService
  ) {}

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this._spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this._spinner.hide();
    this._appService.notifyMe(mensaje, ok);

    if (mensaje && ok) this._toastService.success(mensaje);
    if (mensaje && !ok) this._toastService.warning(mensaje);
  }

  // spinnerOffV2(text: string, ok: boolean = true) {
  //   this._spinner.hide();
  //   (ok) ? this._sweetAlertService.success(text) : this._sweetAlertService.error(text);
  // }

  spinnerOffV2(
    text: string = "",
    showConfirmButton: boolean = false,
    ok: boolean = true
  ) {
    this._spinner.hide();
    ok
      ? this._sweetAlertService.success(text, showConfirmButton)
      : this._sweetAlertService.error(text, showConfirmButton);
  }
}
