import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanLoad {

  private _return_url: string = environment.RETURN_URL;

  constructor(private authenticationService: AuthenticationService,
              private router: Router) { }

  canActivate(): Observable<boolean> | boolean {
    return this.authenticationService.isAuthenticated()
              .pipe(
                tap(resp => {
                  if (!resp) {
                    // window.location.href = this._return_url;
                  }
                })
              );
  };

  canLoad(): Observable<boolean> | boolean {
    return this.authenticationService.isAuthenticated()
              .pipe(
                tap(resp => {
                  if (!resp) {
                    // this.router.navigateByUrl('/authentication/login');
                    // window.location.href = this._return_url;
                  }
                })
              );
  };
}
