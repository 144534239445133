import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetalertService {
  success(text: string, showConfirmButton: boolean) {
    Swal.fire({
      icon: 'success',
      title: 'Operación exitosa',
      html: text,
      confirmButtonColor: '#3e50b4',
      showConfirmButton,
      timer: showConfirmButton ? null : 1500
    });
  }

  error(text: string, showConfirmButton: boolean) {
    Swal.fire({
      icon: 'error',
      title: 'Operación fallida',
      html: text,
      confirmButtonColor: '#3e50b4',
      showConfirmButton,
      timer: showConfirmButton ? null : 1500
    });
  }
}
