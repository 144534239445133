import { Perfil } from './Perfil'
export class Usuario extends Perfil{
    public id                : number;
    public nombre            : string;
    public apellido          : string;
    public email             : string;
    public fecha_creacion    : string;
    public perfil            : number;
    public perfil_descripcion: string;
    public estado            : boolean;
    public estado_descripcion: string;
    public cliente           : any;
    public cliente_nombre    : any;
    public puesto    : any;
    public restablecer_password : boolean;
    public sucursal : string;
    public _comite : any[] = [];
    public equipo : any[] = [];

    constructor(usuario : Usuario){
        super(usuario)   

        if(typeof usuario == 'string'){
            let us = usuario
            usuario = JSON.parse(us)
        }
        this.id                   = usuario.id
        this.nombre               = usuario.nombre
        this.apellido             = usuario.apellido
        this.email                = usuario.email
        this.fecha_creacion       = usuario.fecha_creacion
        this.perfil               = usuario.perfil
        this.perfil_descripcion   = usuario.perfil_descripcion
        this.estado               = usuario.estado
        this.estado_descripcion   = usuario.estado_descripcion
        this.cliente              = usuario.cliente
        this.cliente_nombre       = usuario.cliente_nombre
        this.puesto               = usuario.puesto
        this.restablecer_password = usuario.restablecer_password
        this.equipo = usuario.equipo ? usuario.equipo : []; 

    }

    public set comite(value){
        this._comite = value
    }

    public get comite(){
        return this._comite
    }

    get nombreCompleto(){
        return `${this.nombre} ${this.apellido}`;
    }

    get isAdmin(){
        if(this.perfil === this.perfilAdmin)
            return true
        else
            return false
    }

    get isCliente(){
        if(this.perfil === this.perfilClienteID)
            return true
        else
            return false
    }

    get isOficialDeNegocio(){
        if(this.perfil === this.perfilOficialDeNegocioID)
            return true
        else
            return false        
    }

    get isAnalistaRiesgo(){
        if(this.perfil === this.perfilAnalistaRiesgosID)
            return true
        else
            return false        
    }

    get isGerenteComercial(){
        if(this.perfil === this.perfilGerenciaComercialID)
            return true
        else
            return false        
    }

    get isGerenteGeneral(){
        if(this.perfil === this.perfilGerenciaGeneralID)
            return true
        else
            return false        
    }

    get isJefeDeAdmision(){
        if(this.perfil === this.perfilJefeDeAdmisionID)
            return true
        else
            return false        
    }

    get isAsesorLegal(){
        if(this.perfil === this.perfilAsesorLegalID)
            return true
        else
            return false        
    }

    get isLegal(){
        if(this.perfil === this.perfilLegalID)
            return true
        else
            return false        
    }

    get isJefeDeOperaciones(){
        if(this.perfil === this.perfilJefeDeOperacionesID)
            return true
        else
            return false        
    }

    get isSubGerentecomercial(){
        if(this.perfil === this.perfilSubGerenciaComercialID)
            return true
        else
            return false        
    }

    get isTesoreria(){
        if(this.perfil === this.perfilTesoreriaID)
            return true
        else
            return false        
    }

    get isLiderDeTesoreria(){
        if(this.perfil === this.perfilLiderDeTesoreriaID)
            return true
        else
            return false        
    }

    get isRecepcion(){
        if(this.perfil === this.perfilRecepcionID)
            return true
        else
            return false        
    }

    get isAsistenteCobranzas(){
        if(this.perfil === this.perfilAsistenteCobranzasID)
            return true
        else
            return false        
    }

    get isAnalistaDeOperaciones(){
        if(this.perfil === this.perfilAnalistaOperacionesID)
            return true
        else
            return false        
    }

    get isConsultas(){
        if(this.perfil === this.perfilConsultaID)
            return true
        else
            return false        
    }

    get isLiderDeEquipo(){
        if(this.perfil === this.perfilLiderDeEquipo)
        return true
    else
        return false
    }

}

export interface IUsuario{
    id ?                  : number;
    nombre ?              : string;
    apellido ?            : string;
    email ?               : string;
    fecha_creacion ?      : string;
    perfil ?              : number;
    perfil_descripcion ?  : string;
    estado ?              : boolean;
    estado_descripcion ?  : string;
    cliente ?             : number;
    cliente_nombre ?      : string;
    restablecer_password ?: boolean;
    comite ?              : any[];
}